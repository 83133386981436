import styled from 'styled-components';
import { dark, light, offWhite, primary, white } from '../theme/colors';
import infoWhite from '../assets/svg/info-white.svg';
import { ButtonGroup, Dropdown } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { useContext, useEffect, useState } from 'react';
import { AppContext } from '../context/AppContext';
import { getUserDetails } from '../api/Optimise';

const WrapperDiv = styled.div`
  background: ${dark};
  color: ${white};
  padding: 14px 32px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .btn-group {
    .btn {
      background: none !important;
      border: none !important;
      border-width: 1px;
      padding: 0;
      margin: 0;
      border-radius: 0;

      &:active,
      &:hover,
      &:focus {
        border: none !important;
        outline: none !important;
        box-shadow: none !important;
      }
    }
  }
`;

const StyledImg = styled.img`
  width: 20px;
  height: 20px;
  margin-right: 12px;
`;

const StyledDropdownMenu = styled(Dropdown.Menu)`
  width: 350px;
  border-radius: 20px;
  position: absolute;
  right: 20px;
  top: 50px;
  margin-top: 18px !important;
  padding-top: 16px;
  padding-bottom: 16px;
  border: none;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.15);
  background: ${offWhite};

  a {
    text-decoration: none;
    &:focus,
    &:active {
      background-color: ${primary};
      color: white;
    }
  }
`;

const FullWidthDivider = styled.hr`
  margin: 20px 0;
  border: 0;
  border-top: 1px solid ${light}99;
`;

const AdminBar = ({ user, onSignOut, onViewInfo }) => {
  const history = useHistory();
  const { userSub } = useContext(AppContext);
  const [selectedUser, setSelectedUser] = useState(undefined);

  useEffect(() => {
    if (!!userSub) {
      getUserDetails(userSub).then(user => setSelectedUser(user));
    }
  }, userSub);

  return (
    <WrapperDiv>
      <div>
        <StyledImg src={infoWhite} />
        You’re in Admin Mode.
      </div>
      <div>
        <Dropdown as={ButtonGroup}>
          <Dropdown.Toggle variant="success" id="user-dropdown">
            {selectedUser?.user_sub || user?.attributes?.email || 'User'}
          </Dropdown.Toggle>
          <StyledDropdownMenu align="end">
            <Dropdown.ItemText className="font-weight-bold">
              {/* TODO: Show the email for the currently selected user instead of the admin user*/}
              {selectedUser?.user_sub || user?.attributes?.email}
            </Dropdown.ItemText>
            {userSub && (
              <Dropdown.Item onClick={() => history.push('/app/settings')}>
                View more user info
              </Dropdown.Item>
            )}
            <Dropdown.Item onClick={() => history.push('/app/admin')}>
              Change to a different user
            </Dropdown.Item>
            <FullWidthDivider />
            <Dropdown.Item onClick={() => onSignOut(history)}>
              Sign Out
            </Dropdown.Item>
          </StyledDropdownMenu>
        </Dropdown>
      </div>
    </WrapperDiv>
  );
};

export default AdminBar;
