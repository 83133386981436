import 'react-datepicker/dist/react-datepicker.css';
import styled from 'styled-components';
import Accordion from '../../../ui/Accordion';
import LoadingCard from '../../LoadingCard';
import { primary, secondary } from '../../../theme/colors';
import moment from 'moment';

const Container = styled.div`
  margin-top: 24px;
`;

const Title = styled.h3`
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 10px;
  color: #212529;
  margin-bottom: 5px;
`;

const SavingsGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 24px;
  margin-bottom: 24px;
`;

const SavingsAmount = styled.h3`
  font-size: 32px;
  font-weight: bold;
  color: ${props => (props.isSaving ? secondary : primary)};
  margin-bottom: 8px;
`;

const SavingsDescription = styled.p`
  font-size: 14px;
  line-height: 1.5;
`;

const BulletList = styled.ul`
  padding-left: 20px;
  margin-top: 16px;
`;

const BulletItem = styled.li`
  margin-bottom: 8px;
`;

const SavingsSummary = ({
  savings,
  total,
  fetchingAnalyticsData,
  fetchingLatestAnalyticsData,
  userStartDate,
}) => {
  const accordionData = [
    {
      title: 'How did Loop Optimise make this saving?',
      content: (
        <>
          <p>
            Loop Optimise forecasts how much energy your solar panels will
            generate and how much electricity your house will use.
          </p>
          <p>Loop then tells your battery to:</p>

          <BulletList>
            <BulletItem>
              Charge from the grid when electricity is cheap
            </BulletItem>
            <BulletItem>
              Use energy from the battery when electricity is expensive
            </BulletItem>
            <BulletItem>
              Export to the grid when export prices are high
            </BulletItem>
          </BulletList>
        </>
      ),
    },
  ];

  return (
    <Container>
      {fetchingAnalyticsData ||
      fetchingLatestAnalyticsData ||
      !total ||
      !savings ? (
        <div className="mb-3">
          <LoadingCard />
        </div>
      ) : (
        <SavingsGrid>
          <div>
            <Title>{savings > 0 ? 'Daily Savings' : 'Daily Costs'}</Title>
            <SavingsAmount
              isSaving={
                savings > 0
              }>{`£${Math.abs(savings).toFixed(2)}`}</SavingsAmount>
            <SavingsDescription>
              ↑ Savings compared to your battery's default settings
            </SavingsDescription>
          </div>
          <div>
            <Title>{total > 0 ? 'Total Savings' : 'Total Costs'}</Title>
            <SavingsAmount
              isSaving={
                total > 0
              }>{`£${Math.abs(total).toFixed(2)}`}</SavingsAmount>
            <SavingsDescription>
              ↑ Savings since you joined Loop Optimise on{' '}
              {moment(userStartDate).format('DD/MM/YY')}
            </SavingsDescription>
          </div>
        </SavingsGrid>
      )}

      <Accordion data={accordionData} />
    </Container>
  );
};

export default SavingsSummary;
