import styled from 'styled-components';

const StyledTitle = styled.h3`
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 10px;
  color: #212529;
  margin-bottom: 5px;
`;

export default StyledTitle;
