import { Auth } from 'aws-amplify';
import awsconfig from '../config/aws-exports';

const sendTokensToStreamlit = async () => {
  try {
    // Refresh the cognito user
    await Auth.currentAuthenticatedUser({ bypassCache: true });

    const session = await Auth.currentSession();
    const idToken = session.getIdToken().getJwtToken();
    const accessToken = session.getAccessToken().getJwtToken();
    // TODO: Should we allow streamlit to refresh the token?
    // const refreshToken = user.signInUserSession.refreshToken.token;

    const redirectUrl = `${awsconfig.dashboard_url}/dashboard?id_token=${encodeURIComponent(idToken)}&access_token=${encodeURIComponent(accessToken)}`;
    window.location.href = redirectUrl;
  } catch (error) {
    console.error('Error retrieving tokens:', error);
  }
};

export default sendTokensToStreamlit;
