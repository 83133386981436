export const getUnitList = (manufacturerList, manufacturer) => {
  let unitList = undefined;
  manufacturerList.forEach(item => {
    if (manufacturer === item.manufacturer) {
      unitList = item.unit_list;
    }
  });
  return unitList;
};

export const getUnit = (unitList, unitName) => {
  let unit = undefined;
  unitList.forEach(item => {
    if (item.unit_name === unitName) {
      unit = item;
    }
  });
  return unit;
};
