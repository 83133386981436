import moment from 'moment';
import styled from 'styled-components';
import { StyledH3 } from '../../Styled';
import ImportExportChart from './ImportExportChart';

const Container = styled.div`
  margin-top: 80px;
`;

const H3 = styled(StyledH3)`
  margin-bottom: 5px;

  span.type {
    color: #ff0085;
  }

  span.cost {
    font-weight: normal;
  }
`;

const colorMap = {
  'Cheap rate': '#D8F1E9',
  'Day rate': '#E1D6EA',
  'Peak rate': '#FDF6D5',
};

const GridImportsGraph = ({ dailyAnalyticsData, formattedDate }) => {
  formattedDate = moment(formattedDate).format('DD/MM/YYYY');

  const data = dailyAnalyticsData?.map(row => ({
    data: row,
    earnings: parseFloat(row.actual_import_cost) * -1,
    time: moment(row.start_date).format('HH:mm'),
  }));

  const highestDayEarnings = data?.reduce(
    (acc, row) => (row.earnings > acc ? row.earnings : acc),
    0,
  );

  const boundaryData = data?.map(row => {
    return {
      time: row.time,
      boundary: row?.data?.tariff_rate,
      color: colorMap[row?.data?.tariff_rate],
      value:
        row?.data?.tariff_rate == 'Cheap rate' ||
        row?.data?.tariff_rate == 'Peak rate'
          ? highestDayEarnings
          : 0,
    };
  });

  const importCosts = data?.reduce((acc, row) => acc + row.earnings, 0) / 100;

  const haveCostsForTheDay = data?.some(row => row.earnings > 0);

  return haveCostsForTheDay ? (
    <Container>
      <H3>
        Grid <span className="type">import</span> costs on {formattedDate}:{' '}
        <span className="cost">£{importCosts.toFixed(2)}</span>
      </H3>
      <ImportExportChart
        data={data}
        boundaryData={boundaryData}
        barColor="#ff0085"
        yAxisLabel="Import costs (p)"
      />
    </Container>
  ) : null;
};

export default GridImportsGraph;
