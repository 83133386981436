import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import ReactMarkdown from 'react-markdown';
import withContentfulTag from '../utils/withContentfulTag';
import AppLayout from '../ui/Layout/AppLayout';
import { Button, Container } from 'react-bootstrap';
import errorGraphic from '../assets/svg/404-error-page.svg';
import { contentfulPropTypes } from '../propTypes';

const StyledButton = styled(Button)`
  min-width: 198px;
`;

const RelativeDiv = styled.div`
  @media (min-width: 575px) {
    position: relative;
    top: -70px;
  }
`;

const StyledContainer = styled(Container)`
  max-width: 652px;
`;

const NotFoundContainer = ({ contentful, loading, fetched }) => {
  const history = useHistory();
  return (
    !loading &&
    fetched && (
      <AppLayout
        hideDownload
        hideSidebar
        background="background-color-off-white">
        <StyledContainer className="text-center pt-4">
          <img src={errorGraphic} alt="404 error page not found" />
          <RelativeDiv className="pb-3 pb-lg-1">
            <StyledButton onClick={() => history.push('/')}>
              {contentful?.primaryButtonText}
            </StyledButton>
            <h1 className="pb-2">{contentful?.subtitle}</h1>
            <ReactMarkdown>{contentful?.body}</ReactMarkdown>
          </RelativeDiv>
        </StyledContainer>
      </AppLayout>
    )
  );
};

NotFoundContainer.propTypes = {
  ...contentfulPropTypes,
};

export default withContentfulTag(NotFoundContainer, 'web_app_not_found_page');
