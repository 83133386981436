import styled from 'styled-components';
import batteryIcon from '../../../assets/svg/today/battery.svg';
import moment from 'moment';
import StyledTitle from '../../Styled/StyledTitle';

const Container = styled.div`
  margin-top: 24px;
`;

const Title = styled.h3`
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 10px;
  color: #212529;
  margin-bottom: 5px;
`;

const Timestamp = styled.p`
  font-size: 16px;
  margin-bottom: 12px;
`;

const BatteryInfo = styled.div`
  border: 1px solid #afb6b9;
  border-radius: 8px;
  padding: 10px 16px;
  display: flex;
  align-items: center;
  margin-bottom: 10px;
`;

const BatteryIcon = styled.div`
  width: 60px;
  height: 60px;
  background: linear-gradient(225deg, #6433a5, #2747b1);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 16px;
`;

const BatteryDetails = styled.div`
  flex: 1;
`;

const StatusText = styled.p`
  font-size: 16px;
  margin: 2px 0;
`;

const RecentBatteryStatus = ({
  latestRecord,
  lastInstruction,
  nextInstruction,
}) => {
  const lastRecordMoment = moment(latestRecord?.start_date * 1000);
  return (
    <Container>
      <StyledTitle>Most recent home battery status: </StyledTitle>
      <Timestamp>
        {!!latestRecord?.start_date &&
          `${lastRecordMoment.format('DD/MM/YY [at] HH:mm')} (${lastRecordMoment.fromNow()})`}
      </Timestamp>
      <BatteryInfo>
        <BatteryIcon>
          <img src={batteryIcon} alt="Battery" />
        </BatteryIcon>
        <BatteryDetails>
          <StatusText>
            Battery status:{' '}
            <strong>
              {lastInstruction?.battery_update_command === 'discharge'
                ? 'Discharging'
                : 'Charging'}
            </strong>
          </StatusText>
          <StatusText>
            Battery state of charge:{' '}
            <strong>{Math.floor(lastInstruction?.optimal_target_soc)}%</strong>
          </StatusText>
          <StatusText>
            Next battery action:{' '}
            <strong>
              {nextInstruction?.battery_update_command === 'discharge'
                ? 'Discharge'
                : 'Charge'}{' '}
              at {nextInstruction?.fullTimeVector.substring(11, 16)}
            </strong>
          </StatusText>
        </BatteryDetails>
      </BatteryInfo>
    </Container>
  );
};

export default RecentBatteryStatus;
