import { SignUp } from 'aws-amplify-react';
import LoginLayout from '../ui/Layout/LoginLayout';
import LoginCard from '../ui/LoginCard';
import { Button, Card, Col, Container, Form, Row } from 'react-bootstrap';
import './styles.scss';

/**
 * Extending amplify boilerplate SignUp screen.
 */
class LoopSignUp extends SignUp {
  /**
   * Class constructor
   * @param {Object} props The screen's properties
   */
  constructor(props) {
    super(props);
    this._validAuthStates = ['signUp'];

    this.state = {
      username: '',
    };

    this.signUpFields = [
      {
        label: 'Name',
        key: 'name',
        required: true,
        displayOrder: 1,
        type: 'string',
        placeholder: '',
      },
      {
        label: 'Email',
        key: 'username',
        required: true,
        displayOrder: 2,
        type: 'email',
        placeholder: '',
      },
      {
        label: 'Password',
        key: 'password',
        required: true,
        displayOrder: 3,
        type: 'password',
        placeholder: '',
      },
    ];
  }

  /**
   * Component did update handler
   * @param {Object} prevProps The previous props
   * @param {Object} prevState The previous state
   * @param {Object} snapshot
   */
  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.authState === 'signUp' && !this.tracked) {
      this.tracked = true;
    } else if (this.props.authState !== 'signUp') {
      this.tracked = false;
    }
  }

  /**
   * Show loop forgot password screen
   * @param {Object} theme The theme object
   * @return {JSX.Element}
   */
  showComponent(theme) {
    const { requestPending } = this.state;
    return (
      <LoginLayout>
        <LoginCard>
          <Card.Title as="h1">Sign up to Loop Optimise</Card.Title>
          <Card.Text>
            Loop Optimise will buy and store energy when it's cheap, and sell it
            back to the grid when it’s expensive.
          </Card.Text>
          <Card.Text>
            <a
              href="https://loop.homes/loop-optimise-example/"
              rel="noreferrer"
              target="_blank">
              How does Loop Optimise work?
            </a>
          </Card.Text>
          <Card.Body className="p-0">
            <Container className="text-left">
              <Row>
                <Col className="px-lg-3 px-md-2 px-1">
                  <Form.Group className="mb-md-3 mb-2">
                    <Form.Label htmlFor="name">Your name</Form.Label>
                    <Form.Control
                      id="signup_name"
                      key="name"
                      name="name"
                      onChange={this.handleInputChange}
                      type="text"
                      placeholder="Your name"
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col className="px-lg-3 px-md-2 px-1">
                  <Form.Group className="mb-md-3 mb-2">
                    <Form.Label htmlFor="username">Email address</Form.Label>
                    <Form.Control
                      id="signup_username"
                      key="username"
                      name="username"
                      onChange={e => {
                        e.target.value = e.target.value.toLowerCase().trim();
                        this.handleInputChange(e);
                      }}
                      type="email"
                      placeholder="Email address"
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col className="px-lg-3 px-md-2 px-1">
                  <Form.Group className="mb-md-3 mb-2">
                    <Form.Label htmlFor="password">
                      Create a password
                    </Form.Label>
                    <Form.Control
                      id="signup_password"
                      key="password"
                      name="password"
                      onChange={this.handleInputChange}
                      type="password"
                      placeholder="Create a password"
                    />
                  </Form.Group>
                  <div className="terms text-left color-light">
                    <p>
                      Must be at minimum 8 characters including upper and lower
                      case, number and symbol.
                    </p>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col className="px-lg-3 px-md-2 p-1">
                  <div className="terms text-center mt-3">
                    By clicking on Sign up, you agree to Loop’s{' '}
                    <a href="https://loop.homes/policies/terms-of-use/">
                      Terms of Service.
                    </a>
                  </div>
                  <div className="terms text-center mt-3">
                    To learn more about how Loop collects, uses, shares and
                    protects your personal data please read Loop’s{' '}
                    <a href="https://loop.homes/policies/privacy-policy/">
                      Privacy Policy.
                    </a>
                  </div>
                  <Button
                    variant="primary"
                    type="submit"
                    block
                    disabled={requestPending}
                    onClick={() => {
                      const username = this.inputs?.username || '';
                      this.inputs.username = username.toLowerCase().trim();
                      return this.signUp();
                    }}
                    className="mb-2">
                    Sign up
                  </Button>
                </Col>
              </Row>
              <Row>
                <Col className="px-lg-3 px-md-2 px-1">
                  <Form.Group className="mb-0 mt-1">
                    <hr className="mt-3 mb-4" />
                    <p className="text-center mb-0 d-flex justify-content-between">
                      <span className="signup-text">
                        Already have a Loop account?
                      </span>
                      <Button
                        type="button"
                        variant="outline-primary"
                        onClick={() => super.changeState('signIn')}
                        className="px-4">
                        Sign in
                      </Button>
                    </p>
                  </Form.Group>
                </Col>
              </Row>
            </Container>
          </Card.Body>
        </LoginCard>
      </LoginLayout>
    );
  }
}
export default LoopSignUp;
