import { Button } from 'react-bootstrap';
import CheckMark from '../../assets/svg/disc/check-disc.svg';
import sendTokensToStreamlit from '../../utils/sendTokensToStreamlit';

const ConnectedContainer = () => (
  <>
    <h1 className="mb-3">Connected to your system</h1>
    <div className="mt-1 mb-4">
      <img src={CheckMark} alt="Success checkmark" />
    </div>
    <div>Loop Optimise has successfully connected to your inverter.</div>
    <Button onClick={sendTokensToStreamlit}>Continue</Button>
  </>
);

export default ConnectedContainer;
