import React, { useContext, useEffect, useState } from 'react';
import { Alert, Button, Form } from 'react-bootstrap';
import { Formik, Field } from 'formik';
import { Auth } from 'aws-amplify';
import { useHistory } from 'react-router-dom';
import * as Yup from 'yup';
import cx from 'classnames';
import ErrorLabel from '../../ui/ErrorLabel';
import { getRegion } from '../../api/Location';
import { usePersistedState } from '../../hooks';
import LoopSpinner from '../../ui/LoopSpinner';
import { onboardingPropTypes } from '../../propTypes';
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from 'styled-components';
import { constructAddressId } from '../../utils/addressHelperUtil';
import { OnboardingContext } from '../../context/OnboardingContext';

const StyledButton = styled(Button)`
  padding-left: 0;
  margin-top: 0;
  margin-bottom: 10px;
  text-decoration: none;
`;

const ManualAddressContainer = () => {
  const history = useHistory();
  const { setIsManualAddress, setProgressPercentage } =
    useContext(OnboardingContext);

  const postcodeValidationRegex =
    '^([Gg][Ii][Rr]' +
    ' 0[Aa]{2})|((([A-Za-z][0-9]{1,2})|(([A-Za-z][A-Ha-hJ-Yj-y]' +
    '[0-9]{1,2})|(([A-Za-z][0-9][A-Za-z])|([A-Za-z][A-Ha-hJ-Yj-y]' +
    '[0-9]?[A-Za-z])))) ?[0-9][A-Za-z]{2})$';

  const addressFieldRegex = '^.{0,50}$';

  const addressFieldSchema = Yup.object().shape({
    postcode: Yup.string()
      .test('len', 'Please enter a UK postcode', val => val?.length >= 6)
      .matches(postcodeValidationRegex, 'Please enter a UK postcode')
      .required('Please enter your postcode'),
    flatNumber: Yup.string().matches(
      addressFieldRegex,
      'Flat Number must be fewer than 50 characters',
    ),
    houseNumber: Yup.string().matches(
      addressFieldRegex,
      'House Number must be fewer than 50 characters',
    ),
    houseName: Yup.string().matches(
      addressFieldRegex,
      'House name must be fewer than 50 characters',
    ),
    addressLine1: Yup.string()
      .matches(addressFieldRegex, 'Address must be fewer than 50 characters')
      .required('Address is required'),
    addressLine2: Yup.string().matches(
      addressFieldRegex,
      'Address line 2 must be fewer than 50 characters',
    ),
    town: Yup.string()
      .matches(addressFieldRegex, 'Town name must be fewer than 50 characters')
      .required('Town/City is required'),
    county: Yup.string()
      .matches(
        addressFieldRegex,
        'County name must be fewer than 50 characters',
      )
      .required('County is required'),
  });

  // eslint-disable-next-line no-unused-vars
  const [postcode, setPostcode] = usePersistedState('postcode', '');

  const onSubmitManualAddress = async ({
    flatNumber,
    houseNumber,
    houseName,
    addressLine1,
    addressLine2,
    town,
    county,
    postcode,
  }) => {
    setShowAlert(false);

    try {
      await getRegion(postcode.toUpperCase());

      const address = constructAddressId(
        flatNumber,
        houseNumber,
        houseName,
        addressLine1,
        addressLine2,
        town,
        county,
        postcode,
      );

      const user = await Auth.currentAuthenticatedUser();
      const attributes = {
        address,
        'custom:postcode': postcode,
      };
      await Auth.updateUserAttributes(user, attributes);

      setIsManualAddress(true);

      history.push(`/onboarding/tariff`);
    } catch (submitManualAddressError) {
      let parsedManualAddressError;
      let jsonParseError = false;
      try {
        parsedManualAddressError = JSON.parse(submitManualAddressError.message);
      } catch {
        jsonParseError = true;
      }
      if (!jsonParseError && parsedManualAddressError.message) {
        addAlert('danger', parsedManualAddressError?.message);
      } else {
        history.push(`/onboarding/system-unavailable`);
      }
    }
  };

  const [showAlert, setShowAlert] = useState(false);
  const [showAddressLine2, setShowAddressLine2] = useState(false);
  const [alert, setAlert] = useState(undefined);

  useEffect(() => {
    setProgressPercentage(10);
  }, []);

  const addAlert = (variant, message) => {
    setShowAlert(true);
    setAlert({ variant, message });
    window.scrollTo(0, 0);
  };

  const hideAlert = () => {
    setShowAlert(false);
    setAlert(undefined);
  };

  return (
    <>
      <h1 className="mb-3">What's your address?</h1>
      <div>
        <>
          <p>
            Please enter your address. The smart meter you are connecting to
            must be at this address.
          </p>
          <div className="text-left">
            <Formik
              validationSchema={addressFieldSchema}
              onSubmit={onSubmitManualAddress}
              initialValues={{
                flatNumber: '',
                houseNumber: '',
                houseName: '',
                addressLine1: '',
                addressLine2: '',
                town: '',
                county: '',
                postcode: postcode,
              }}
              validateOnChange>
              {({ handleSubmit, isValid, isSubmitting }) => (
                <Form onSubmit={handleSubmit}>
                  {showAlert && alert && (
                    <div>
                      <Alert
                        variant={alert.variant}
                        onClose={() => hideAlert()}
                        dismissible>
                        {alert.message}
                      </Alert>
                    </div>
                  )}

                  <Field name="flatNumber">
                    {({ field, meta }) => (
                      <Form.Group controlId="flatNumber" className="text-left">
                        <Form.Label>Flat Number</Form.Label> (optional)
                        <Form.Control
                          type="text"
                          value={field.value}
                          onChange={field.onChange}
                          onBlur={field.onBlur}
                          isValid={!meta.error && meta.touched}
                          placeholder="Flat number"
                          className={cx({ error: !!meta.error })}
                        />
                        {meta.error && meta.touched && (
                          <ErrorLabel label={meta.error} />
                        )}
                      </Form.Group>
                    )}
                  </Field>

                  <Field name="houseNumber">
                    {({ field, meta }) => (
                      <Form.Group controlId="houseNumber" className="text-left">
                        <Form.Label>House Number</Form.Label> (optional)
                        <Form.Control
                          type="text"
                          value={field.value}
                          onChange={field.onChange}
                          onBlur={field.onBlur}
                          isValid={!meta.error && meta.touched}
                          placeholder="House number"
                          className={cx({ error: !!meta.error })}
                        />
                        {meta.error && meta.touched && (
                          <ErrorLabel label={meta.error} />
                        )}
                      </Form.Group>
                    )}
                  </Field>

                  <Field name="houseName">
                    {({ field, meta }) => (
                      <Form.Group controlId="houseName" className="text-left">
                        <Form.Label>House Name</Form.Label> (optional)
                        <Form.Control
                          type="text"
                          value={field.value}
                          onChange={field.onChange}
                          onBlur={field.onBlur}
                          isValid={!meta.error && meta.touched}
                          placeholder="House name"
                          className={cx({ error: !!meta.error })}
                        />
                        {meta.error && meta.touched && (
                          <ErrorLabel label={meta.error} />
                        )}
                      </Form.Group>
                    )}
                  </Field>

                  <Field name="addressLine1">
                    {({ field, meta }) => (
                      <Form.Group
                        controlId="addressLine1"
                        className={cx({
                          'text-left': true,
                          'mb-0': !showAddressLine2,
                        })}>
                        <Form.Label>Address</Form.Label>
                        <Form.Control
                          type="text"
                          value={field.value}
                          onChange={field.onChange}
                          onBlur={field.onBlur}
                          isValid={!meta.error && meta.touched}
                          placeholder="Address"
                          className={cx({ error: !!meta.error })}
                        />
                        {meta.error && meta.touched && (
                          <ErrorLabel label={meta.error} />
                        )}
                      </Form.Group>
                    )}
                  </Field>

                  {showAddressLine2 ? (
                    <Field name="addressLine2">
                      {({ field, meta }) => (
                        <Form.Group
                          controlId="addressLine2"
                          className="text-left">
                          <Form.Label>Address Line 2</Form.Label> (optional)
                          <Form.Control
                            type="text"
                            value={field.value}
                            onChange={field.onChange}
                            onBlur={field.onBlur}
                            isValid={!meta.error && meta.touched}
                            placeholder="Address Line 2"
                            className={cx({ error: !!meta.error })}
                          />
                          {meta.error && meta.touched && (
                            <ErrorLabel label={meta.error} />
                          )}
                        </Form.Group>
                      )}
                    </Field>
                  ) : (
                    <StyledButton
                      variant="link"
                      onClick={() => setShowAddressLine2(true)}>
                      <FontAwesomeIcon icon={faPlusCircle} size={'sm'} />
                      &nbsp;Add address line 2
                    </StyledButton>
                  )}

                  <Field name="town">
                    {({ field, meta }) => (
                      <Form.Group controlId="town" className="text-left">
                        <Form.Label>Town/City</Form.Label>
                        <Form.Control
                          type="text"
                          value={field.value}
                          onChange={field.onChange}
                          onBlur={field.onBlur}
                          isValid={!meta.error && meta.touched}
                          placeholder="Town/City"
                          className={cx({ error: !!meta.error })}
                        />
                        {meta.error && meta.touched && (
                          <ErrorLabel label={meta.error} />
                        )}
                      </Form.Group>
                    )}
                  </Field>

                  <Field name="county">
                    {({ field, meta }) => (
                      <Form.Group controlId="county" className="text-left">
                        <Form.Label>County</Form.Label>
                        <Form.Control
                          type="text"
                          value={field.value}
                          onChange={field.onChange}
                          onBlur={field.onBlur}
                          isValid={!meta.error && meta.touched}
                          placeholder="County"
                          className={cx({ error: !!meta.error })}
                        />
                        {meta.error && meta.touched && (
                          <ErrorLabel label={meta.error} />
                        )}
                      </Form.Group>
                    )}
                  </Field>

                  <Field name="postcode">
                    {({ field, meta }) => (
                      <Form.Group controlId="postcode" className="text-left">
                        <Form.Label>Postcode</Form.Label>
                        <Form.Control
                          type="text"
                          value={field.value}
                          onChange={field.onChange}
                          onBlur={field.onBlur}
                          isValid={!meta.error && meta.touched}
                          placeholder="Postcode"
                          className={cx({ error: !!meta.error })}
                        />
                        {meta.error && meta.touched && (
                          <ErrorLabel label={meta.error} />
                        )}
                      </Form.Group>
                    )}
                  </Field>

                  <Form.Group className="mb-4">
                    <div>
                      <Form.Label>Country</Form.Label>
                    </div>
                    <div>UK</div>
                  </Form.Group>

                  <Form.Group>
                    {!isSubmitting ? (
                      <Button
                        variant="primary"
                        disabled={!isValid || isSubmitting}
                        block
                        type="submit">
                        Continue
                      </Button>
                    ) : (
                      <LoopSpinner />
                    )}
                  </Form.Group>
                </Form>
              )}
            </Formik>
          </div>
        </>
      </div>
    </>
  );
};

ManualAddressContainer.propTypes = {
  ...onboardingPropTypes,
};

export default ManualAddressContainer;
