/**
 * Processes the data for the battery state of charge chart
 *
 * @param {Array} data
 * @returns {Array}
 */
export const processSocChartData = data => {
  return data.map(data => ({
    x: data.time,
    y: parseInt(data.battery_soc),
  }));
};

/**
 * Processes the data for the system energy history chart
 *
 * @param {Array} data
 * @returns {Array}
 */
export const processSystemEnergyHistoryChartData = data => {
  return data.map(data => ({
    time: new Date(data.target_datetime_utc).toLocaleTimeString('en-GB', {
      hour: '2-digit',
      minute: '2-digit',
    }),
    // using snake case to avoid errors in formatting in charts
    solarGeneration: parseFloat(data.solar_generation),
    consumption: parseFloat(data.load_consumption),
    imported: parseFloat(data.imported),
    exported: -parseFloat(data.exported),
  }));
};

/**
 * Calculates the y domain for the system energy history chart
 *
 * @param {Array} processedData
 * @param {Function} setYDomain
 * @returns {void}
 */
export const calculateYDomain = (processedData, setYDomain) => {
  let minY = 0;
  let maxY = 0;

  processedData.forEach(item => {
    const sum =
      item.solarGeneration + item.consumption + item.imported + item.exported;
    minY = Math.min(minY, sum, item.exported);
    maxY = Math.max(
      maxY,
      sum,
      item.solarGeneration,
      item.consumption,
      item.imported,
    );
  });

  setYDomain([minY * 1.1, maxY * 1.1]);
};
