import { SignIn } from 'aws-amplify-react';
import LoginLayout from '../ui/Layout/LoginLayout';
import LoginCard from '../ui/LoginCard';
import { Button, Card, Form } from 'react-bootstrap';
import './styles.scss';

/**
 * Extending amplify boilerplate SignIn screen.
 */
export default class LoopSignIn extends SignIn {
  /**
   * Class constructor
   * @param {Object} props The screen's properties
   */
  constructor(props) {
    super(props);
    this._validAuthStates = ['signIn', 'signedOut', 'signedUp'];
    this.onKeyUp = this.onKeyUp.bind(this);
  }

  /**
   * onKeyUp event handler
   * @param {Object} event The event payload
   */
  onKeyUp(event) {
    if (event.charCode === 13) {
      super.signIn(event);
    }
  }

  /**
   * Component did mount handler
   */
  componentDidMount() {
    this.tracked = false;
  }

  /**
   * Component did update handler
   * @param {Object} prevProps The previous props
   * @param {Object} prevState The previous state
   * @param {Object} snapshot
   */
  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.authState === 'signIn' && !this.tracked) {
      this.tracked = true;
      if (this.redirect) {
        this.redirect = false;
      }
    } else if (this.props.authState !== 'signIn') {
      this.tracked = false;
    }
  }

  /**
   * Show loop forgot password screen
   * @param {Object} theme The theme object
   * @return {JSX.Element}
   */
  showComponent(theme) {
    if (window.location.pathname === '/signup') {
      this.redirect = true;
      super.changeState('signUp');
      // check if there is a referral_id param
      const referralId = new URLSearchParams(window.location.search).get(
        'referral_id',
      );
      // check for stored referral_id
      const storedReferralId = localStorage.getItem('referral_id');
      if (referralId && !storedReferralId) {
        // store the referral_id for after signup
        localStorage.setItem('referral_id', referralId);
      }
      window.history.pushState(
        undefined,
        undefined,
        `/${window.location.search}`,
      );
    }
    const { loading } = this.state;
    return (
      <LoginLayout>
        <LoginCard>
          <Card.Title as="h1">Sign in to Loop Optimise</Card.Title>
          <Card.Text>
            Loop Optimise will buy and store energy when it's cheap, and sell it
            back to the grid when it’s expensive.
          </Card.Text>
          <Card.Text>
            <a
              href="https://loop.homes/loop-optimise-example/"
              rel="noreferrer"
              target="_blank">
              How does Loop Optimise work?
            </a>
          </Card.Text>
          <Form className="text-left">
            <Form.Group>
              <Form.Label htmlFor="username">Email address</Form.Label>
              <Form.Control
                id="signin_username"
                key="username"
                name="username"
                onChange={e => {
                  e.target.value = e.target.value.toLowerCase().trim();
                  this.handleInputChange(e);
                }}
                onKeyPress={this.onKeyUp}
                type="text"
                placeholder="Email address"
              />
            </Form.Group>
            <Form.Group>
              <Form.Label htmlFor="password">Password</Form.Label>
              <Form.Control
                id="signin_password"
                key="password"
                name="password"
                onChange={this.handleInputChange}
                onKeyPress={this.onKeyUp}
                type="password"
                placeholder="Password"
              />
              <p className="mt-2">
                <Button
                  variant="link"
                  onClick={() => super.changeState('forgotPassword')}>
                  Forgot your password?{' '}
                </Button>
              </p>
            </Form.Group>
            <Form.Group>
              <Button
                type="button"
                variant="primary"
                block
                disabled={loading}
                onClick={e => super.signIn(e)}>
                Sign In
              </Button>
            </Form.Group>
            <Form.Group className="mb-1">
              <p className="text-center">
                Already have a sign in code?{' '}
                <Button
                  variant="link"
                  onClick={() => super.changeState('confirmSignUp')}>
                  Verify code
                </Button>
              </p>
            </Form.Group>
            <Form.Group className="mb-0 mt-1">
              <hr className="my-3" />
              <p className="text-center mb-0 d-flex justify-content-between">
                <span className="signup-text">
                  Don&apos;t have a Loop Optimise account?{' '}
                </span>
                <Button
                  type="button"
                  variant="outline-primary"
                  onClick={() => super.changeState('signUp')}
                  className="px-4">
                  Sign up
                </Button>
              </p>
            </Form.Group>
          </Form>
        </LoginCard>
      </LoginLayout>
    );
  }
}
