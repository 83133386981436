import React, { useContext, useEffect, useState } from 'react';
import { Button, Form, InputGroup } from 'react-bootstrap';
import { Formik, Field } from 'formik';
import { useHistory } from 'react-router-dom';
import * as Yup from 'yup';
import cx from 'classnames';
import styled from 'styled-components';
import ErrorLabel from '../../ui/ErrorLabel';
import LabelInputGroup from '../../ui/Styled/LabelInputGroup';
import LoopSpinner from '../../ui/LoopSpinner';
import { onboardingPropTypes } from '../../propTypes';
import { OnboardingContext } from '../../context/OnboardingContext';
import { black, extraLight, primary, white } from '../../theme/colors';
import InfoModal from '../../ui/Modal/InfoModal';

const StyledTabWrapper = styled.div`
  background: ${extraLight};
  height: 43px;
  border-radius: 23px;
`;

const StyledTabButton = styled(Button)`
  width: 50%;
  margin: 0;
  background: none;
  font-weight: 400;
  color: ${black};
  border: 1px solid ${extraLight};

  &.selected {
    border: 1px solid ${primary};
    background: ${white};
    color: ${black};
  }
`;

const PvSystemContainer = () => {
  const history = useHistory();
  const VIEWS = {
    SYSTEM_SIZE: 'SYSTEM_SIZE',
    NUM_PANELS: 'NUM_PANELS',
  };
  const [currentView, setCurrentView] = useState(VIEWS.SYSTEM_SIZE);
  const [showModal, setShowModal] = useState(false);
  const {
    setProgressPercentage,
    pvSystemSize,
    setPvSystemSize,
    numPanels,
    setNumPanels,
  } = useContext(OnboardingContext);

  const pvSystemSizeSchema = Yup.object({
    pvSystemSize: Yup.number()
      .required('Please provide the size of your PV System')
      .min(1, 'The PV system must be at least 1kW')
      .max(100, 'The PV system must be less than or equal to 100kW')
      .test(
        'is-decimal',
        'The value must have 2 or fewer decimal places',
        value => {
          if (value == null) return true; // Skip if field is empty
          return /^\d+(\.\d{0,2})?$/.test(value.toString());
        },
      ),
  });
  const numPanelsSchema = Yup.object({
    numPanels: Yup.number()
      .required('Please provide the number of panels')
      .min(1, 'You should have at least 1 panel')
      .max(99, 'You should have less than 100 panels'),
  });

  const onSubmitPvSystemSize = ({ pvSystemSize }) => {
    setPvSystemSize(pvSystemSize);
    history.push('/onboarding/solar-panels');
  };

  const onSubmitNumPanels = ({ numPanels }) => {
    setNumPanels(numPanels);
    history.push('/onboarding/solar-panels');
  };

  useEffect(() => {
    setProgressPercentage(30);
  }, []);

  return (
    <>
      <h1 className="mb-3">What is the power of your PV system?</h1>
      <div>
        <>
          <p>
            If you don’t know the power of your system, click to enter how many
            solar panels you have and we'll estimate the power of your system
            from the number of panels.
          </p>
          <p>
            <Button variant="link" onClick={() => setShowModal(true)}>
              How do I know the the size of my PV system?
            </Button>
          </p>
          <StyledTabWrapper>
            <StyledTabButton
              className={cx({ selected: currentView === VIEWS.SYSTEM_SIZE })}
              onClick={() => setCurrentView(VIEWS.SYSTEM_SIZE)}>
              PV system size
            </StyledTabButton>
            <StyledTabButton
              className={cx({ selected: currentView === VIEWS.NUM_PANELS })}
              onClick={() => setCurrentView(VIEWS.NUM_PANELS)}>
              No. of panels
            </StyledTabButton>
          </StyledTabWrapper>
          <div className="text-left mt-5">
            {currentView === VIEWS.SYSTEM_SIZE && (
              <Formik
                validationSchema={pvSystemSizeSchema}
                onSubmit={onSubmitPvSystemSize}
                initialValues={{
                  pvSystemSize,
                }}
                validateOnChange>
                {({ handleSubmit, isValid, isSubmitting }) => (
                  <Form onSubmit={handleSubmit}>
                    <Field name="pvSystemSize">
                      {({ field, meta }) => (
                        <Form.Group
                          controlId="pvSystemSize"
                          className="text-left">
                          <Form.Label>PV system size</Form.Label>
                          <LabelInputGroup>
                            <Form.Control
                              type="number"
                              step="any"
                              min="1"
                              max="100"
                              value={field.value}
                              onChange={e => {
                                const value = e.target.value;
                                if (
                                  value === '' ||
                                  (Number(value) >= 1 && Number(value) <= 100)
                                ) {
                                  field.onChange(e);
                                }
                              }}
                              onBlur={e => {
                                const value = e.target.value;
                                if (value !== '' && !isNaN(value)) {
                                  e.target.value = Number(value).toFixed(2);
                                }
                                field.onBlur(e);
                              }}
                              isValid={!meta.error && meta.touched}
                              placeholder="PV system size"
                              className={cx({ error: !!meta.error })}
                            />
                            <InputGroup.Text
                              className={cx({
                                error: !!meta.error,
                                'is-valid': !meta.error && meta.touched,
                              })}>
                              <div className="divider">kW</div>
                            </InputGroup.Text>
                          </LabelInputGroup>
                          {meta.error && meta.touched && (
                            <ErrorLabel label={meta.error} />
                          )}
                        </Form.Group>
                      )}
                    </Field>
                    <Form.Group>
                      {!isSubmitting ? (
                        <Button
                          variant="primary"
                          disabled={!isValid || isSubmitting}
                          block
                          type="submit">
                          Continue
                        </Button>
                      ) : (
                        <LoopSpinner />
                      )}
                    </Form.Group>
                  </Form>
                )}
              </Formik>
            )}
            {currentView === VIEWS.NUM_PANELS && (
              <Formik
                validationSchema={numPanelsSchema}
                onSubmit={onSubmitNumPanels}
                initialValues={{
                  numPanels,
                }}
                validateOnChange>
                {({ handleSubmit, isValid, isSubmitting }) => (
                  <Form onSubmit={handleSubmit}>
                    <Field name="numPanels">
                      {({ field, meta }) => (
                        <Form.Group controlId="numPanels" className="text-left">
                          <Form.Label>Number of panels</Form.Label>
                          <Form.Control
                            type="number"
                            min="1"
                            max="100"
                            value={field.value}
                            onChange={field.onChange}
                            onBlur={field.onBlur}
                            isValid={!meta.error && meta.touched}
                            placeholder="Number of panels"
                            className={cx({ error: !!meta.error })}
                          />
                          {meta.error && meta.touched && (
                            <ErrorLabel label={meta.error} />
                          )}
                        </Form.Group>
                      )}
                    </Field>
                    <Form.Group>
                      {!isSubmitting ? (
                        <Button
                          variant="primary"
                          disabled={!isValid || isSubmitting}
                          block
                          type="submit">
                          Continue
                        </Button>
                      ) : (
                        <LoopSpinner />
                      )}
                    </Form.Group>
                  </Form>
                )}
              </Formik>
            )}
          </div>
        </>
      </div>
      <InfoModal
        show={showModal}
        onClose={() => setShowModal(false)}
        infoContent={
          <div>
            You can find this on your MCS certificate or the quote you received
            from your installer. If you can't find these details, click to enter
            the number of panels instead.
          </div>
        }
      />
    </>
  );
};

PvSystemContainer.propTypes = {
  ...onboardingPropTypes,
};

export default PvSystemContainer;
