import moment from 'moment-timezone';

// We use a literal space for the description key as this will not be shown in the tooltip but is still a valid key
const DESCRIPTION_KEY = 'description';
const EXPORT_SOLAR = 'Export excess solar generation rather than storing it.';
const AVERAGE_COST = 'Average cost electricity.';
const HOLD_BATTERY =
  'Hold the battery state of charge to be used at peak times.';
const CHARGE_BATTERY = 'Charge the battery when electricity is cheap.';
const USE_BATTERY = 'Use the battery and export when electricity is expensive.';

export const getSavingsTotal = savings => {
  let savingsTotal = 0;
  let dumbBatteryTotal = 0;
  savings.forEach(item => {
    savingsTotal += item.battery_earning - item.battery_cost;
    dumbBatteryTotal += item.dumb_battery_earning - item.dumb_battery_cost;
  });
  return savingsTotal - dumbBatteryTotal;
};

export const getQuantile = (arr, q) => {
  const sorted = arr.slice().sort((a, b) => a - b);
  const pos = (sorted.length - 1) * q;
  const base = Math.floor(pos);
  const rest = pos - base;

  if (sorted[base + 1] !== undefined) {
    return sorted[base] + rest * (sorted[base + 1] - sorted[base]);
  } else {
    return sorted[base];
  }
};

export const importExportPeriodRanking = (analyticsData, tariff) => {
  let cheapTariffImportRate,
    peakTariffImportRate,
    cheapTariffExportRate,
    peakTariffExportRate;

  const importRates = analyticsData.map(record =>
    parseFloat(record.import_rate),
  );
  const exportRates = analyticsData.map(record =>
    parseFloat(record.export_rate),
  );

  if (tariff?.endsWith('flux')) {
    cheapTariffImportRate = Math.min(...importRates);
    peakTariffImportRate = Math.max(...importRates);
    cheapTariffExportRate = Math.min(...exportRates);
    peakTariffExportRate = Math.max(...exportRates);
    const tariffRates = [...new Set(importRates)];

    analyticsData.forEach(record => {
      record.tariff_rate = 'Day rate';
      record[DESCRIPTION_KEY] = HOLD_BATTERY;

      if (
        parseFloat(record.solar_generation) >
        parseFloat(record.load_consumption)
      ) {
        record[DESCRIPTION_KEY] = EXPORT_SOLAR;
      } else if (
        parseFloat(record.solar_generation) <
        parseFloat(record.load_consumption)
      ) {
        record[DESCRIPTION_KEY] = AVERAGE_COST;
      }

      if (parseFloat(record.import_rate) === Math.min(...tariffRates)) {
        record.tariff_rate = 'Cheap rate';
        record[DESCRIPTION_KEY] = CHARGE_BATTERY;
      }

      if (parseFloat(record.import_rate) === Math.max(...tariffRates)) {
        record.tariff_rate = 'Peak rate';
        record[DESCRIPTION_KEY] = USE_BATTERY;
      }
    });
  } else if (
    tariff?.endsWith('intelligent-go-seg') ||
    tariff?.endsWith('electric-driver-v7') ||
    tariff?.endsWith('intelligent-go-outgoing')
  ) {
    cheapTariffImportRate = Math.min(...importRates);
    peakTariffImportRate = Math.max(...importRates);
    cheapTariffExportRate = Math.min(...exportRates);
    peakTariffExportRate = Math.max(...exportRates);
    const tariffRates = [...new Set(importRates)];

    analyticsData.forEach(record => {
      record.tariff_rate = 'Day rate';
      record[DESCRIPTION_KEY] = HOLD_BATTERY;

      if (
        parseFloat(record.solar_generation) >
        parseFloat(record.load_consumption)
      ) {
        record[DESCRIPTION_KEY] = EXPORT_SOLAR;
      } else if (
        parseFloat(record.solar_generation) <
        parseFloat(record.load_consumption)
      ) {
        record[DESCRIPTION_KEY] = AVERAGE_COST;
      }

      if (parseFloat(record.import_rate) === Math.min(...tariffRates)) {
        record.tariff_rate = 'Cheap rate';
        record[DESCRIPTION_KEY] = CHARGE_BATTERY;
      }
    });
  } else {
    cheapTariffImportRate = getQuantile(importRates, 0.2);
    peakTariffImportRate = getQuantile(importRates, 0.8);
    cheapTariffExportRate = getQuantile(exportRates, 0.2);
    peakTariffExportRate = getQuantile(exportRates, 0.8);

    analyticsData.forEach(record => {
      record.tariff_rate = 'Day rate';
      record[DESCRIPTION_KEY] = HOLD_BATTERY;

      if (
        parseFloat(record.solar_generation) >
        parseFloat(record.load_consumption)
      ) {
        record[DESCRIPTION_KEY] = EXPORT_SOLAR;
      } else if (
        parseFloat(record.solar_generation) <
        parseFloat(record.load_consumption)
      ) {
        record[DESCRIPTION_KEY] = AVERAGE_COST;
      }

      if (parseFloat(record.import_rate) <= cheapTariffImportRate) {
        record.tariff_rate = 'Cheap rate';
        record[DESCRIPTION_KEY] = CHARGE_BATTERY;
      }

      if (parseFloat(record.import_rate) >= peakTariffImportRate) {
        record.tariff_rate = 'Peak rate';
        record[DESCRIPTION_KEY] = USE_BATTERY;
      }
    });
  }

  return {
    processedData: analyticsData,
    cheapTariffImportRate,
    peakTariffImportRate,
    cheapTariffExportRate,
    peakTariffExportRate,
  };
};

export const prepareSavingsChartData = analyticsData =>
  analyticsData.map(row => ({
    ...row,
    start_date: moment(row.start_date).tz('Europe/London').toISOString(),
    solar_generation: parseFloat(row.solar_generation),
    load_consumption: parseFloat(row.load_consumption),
    import_rate: parseFloat(row.import_rate),
    export_rate: parseFloat(row.export_rate),
    imported: parseFloat(row.imported),
    exported: parseFloat(row.exported),
    alternative_import_cost: parseFloat(row.alternative_import_cost),
    actual_import_cost: -parseFloat(row.actual_import_cost),
    actual_export_earning: -parseFloat(row.actual_export_earning),
    time: moment(row.start_date).format('HH:mm'),
  }));
