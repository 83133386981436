import { Accordion as BootstrapAccordion } from 'react-bootstrap';
import styled from 'styled-components';

const StyledAccordion = styled(BootstrapAccordion)`
  > div {
    margin-top: 12px;

    &:first-child {
      margin-top: 0;
    }
  }
`;

const StyledAccordionItem = styled.div`
  border: 1px solid #afb6b9;
  border-radius: 8px;
  padding: 8px 12px;
`;

const StyledAccordionToggle = styled(BootstrapAccordion.Toggle)`
  border: none;
  padding: 0;
  width: 100%;
  text-align: left;
  background-color: white;
  cursor: pointer;
`;

const StyledAccordionCollapse = styled(BootstrapAccordion.Collapse)`
  margin-top: 16px;
`;

const Accordion = ({ data, defaultActiveKey = '' }) => (
  <StyledAccordion defaultActiveKey={defaultActiveKey}>
    {data?.map((item, index) => (
      <StyledAccordionItem key={index}>
        <StyledAccordionToggle variant="link" eventKey={index.toString()}>
          {item?.title}
        </StyledAccordionToggle>
        <StyledAccordionCollapse eventKey={index.toString()}>
          {item?.content}
        </StyledAccordionCollapse>
      </StyledAccordionItem>
    ))}
  </StyledAccordion>
);

export default Accordion;
