import { VictoryAxis, VictoryBar, VictoryChart, VictoryLegend } from 'victory';

const ImportExportChart = ({ data, boundaryData, barColor, yAxisLabel }) => {
  const legendData = [
    { name: 'Cheap rate', symbol: { fill: '#D8F1E9' } },
    { name: 'Day rate', symbol: { fill: 'transparent', stroke: 'black' } },
    { name: 'Peak rate', symbol: { fill: '#FDF6D5' } },
  ];

  return (
    <div style={{ position: 'relative' }}>
      <VictoryChart
        domainPadding={10}
        width={648}
        height={400}
        padding={{ top: 30, right: 50, bottom: 51, left: 50 }}>
        <VictoryLegend
          x={50}
          y={0}
          orientation="horizontal"
          gutter={20}
          style={{ title: { fontSize: 14 } }}
          data={legendData}
        />
        <VictoryAxis
          tickFormat={(t, index) => {
            if (index % 6 === 0) {
              return t;
            }
            return '';
          }}
          style={{
            axis: { stroke: '#afb6b9' },
            ticks: { stroke: 'transparent', size: 5 },
            tickLabels: {
              fontSize: 14,
              padding: 5,
              angle: -90,
              textAnchor: 'end',
            },
            grid: { stroke: 'transparent' },
          }}
          offsetY={50}
        />
        <VictoryAxis
          dependentAxis
          label={yAxisLabel}
          style={{
            axis: { stroke: 'transparent' },
            ticks: { stroke: 'transparent' },
            tickLabels: {
              fill: 'black',
              fontSize: 14,
              padding: 5,
            },
            grid: {
              stroke: '#afb6b9',
              opacity: 0.3,
              strokeWidth: 0.5,
              strokeDasharray: '0',
            },
            axisLabel: {
              fontSize: 16,
              padding: 30,
              textAnchor: 'middle',
              fontWeight: 'bold',
              fill: barColor,
            },
          }}
        />
        <VictoryBar
          data={boundaryData}
          x="time"
          y="value"
          barWidth={12}
          style={{ data: { fill: ({ datum }) => datum.color } }}
        />
        <VictoryBar
          data={data}
          x="time"
          y="earnings"
          barWidth={10}
          style={{ data: { fill: barColor } }}
        />
      </VictoryChart>
    </div>
  );
};

export default ImportExportChart;
