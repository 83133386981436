import { useContext, useEffect, useState } from 'react';
import InformationPanel from './InformationPanel';
import { AppContext } from '../../../context/AppContext';
import { getTariffById } from '../../../utils/tariffUtils';

const TariffInformation = () => {
  const { userDetails } = useContext(AppContext);
  const [tariff, setTariff] = useState(undefined);

  useEffect(() => {
    userDetails?.tariff_details?.tariff_name &&
      setTariff(getTariffById(userDetails?.tariff_details?.tariff_name));
  }, [userDetails]);

  return (
    <InformationPanel
      title="Tariff Information"
      isLoading={!userDetails?.tariff_details?.tariff_name}>
      <li>Energy Supplier: {tariff?.supplier}</li>
      <li>Tariff Name: {tariff?.tariff}</li>
    </InformationPanel>
  );
};

export default TariffInformation;
