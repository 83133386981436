import { InputGroup } from 'react-bootstrap';
import styled from 'styled-components';
import { error, light, success } from '../../theme/colors';

const LabelInputGroup = styled(InputGroup)`
  .form-control {
    border-right: none;
    border-color: ${light};

    &.is-valid {
      border-color: ${success};
    }
    &.error {
      border-color: ${error};
    }
  }
  .input-group-text {
    background: none;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-left: none;
    border-color: ${light};
    padding: 2px 0;

    .divider {
      border-left: 1px solid ${light};
      padding: 2px 12px;
    }

    &.is-valid {
      border-color: ${success};

      .divider {
        border-color: ${success};
      }
    }
    &.error {
      border-color: ${error};

      .divider {
        border-color: ${error};
      }
    }
  }
`;

export default LabelInputGroup;
