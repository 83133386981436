import { useContext, useState, useEffect } from 'react';
import { Button, Form } from 'react-bootstrap';
import { Formik, Field } from 'formik';
import { useHistory } from 'react-router-dom';
import * as Yup from 'yup';
import cx from 'classnames';
import styled from 'styled-components';
import ErrorLabel from '../../../ui/ErrorLabel';
import LoopSpinner from '../../../ui/LoopSpinner';
import { OnboardingContext } from '../../../context/OnboardingContext';
import InfoModal from '../../../ui/Modal/InfoModal';
import { createInverterAuthentication } from '../../../api/Optimise';
import generateRandomString from '../../../utils/generateRandomString';

const borderStyles = `
    border: 1px solid #afb6b9;
    border-radius: 4px;
    padding: 20px 20px 0 20px;
  `;

const StyledFormContainer = styled.div`
  margin-top: 2rem;
  text-align: left;
  ${({ logo }) => logo && borderStyles};
`;

const StyledLogo = styled.img`
  margin: 0 auto;
  display: block;
  margin-top: 0.5rem;
  margin-bottom: 2rem;
`;

const StyledButton = styled.button`
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
  outline: inherit;
  text-decoration: underline !important;
  color: #ff0085;
  display: inline-block;
`;

const BaseConnectionContainer = ({
  fields,
  logo = null,
  exampleModalContent = null,
  inverterManufacturer,
}) => {
  const history = useHistory();
  const [error, setError] = useState(undefined);
  const { setProgressPercentage, setManufacturerDetails } =
    useContext(OnboardingContext);
  const [showInfo, setShowInfo] = useState(false);
  const [infoContent, setInfoContent] = useState(undefined);

  const validationSchema = Yup.object().shape(
    fields.reduce((acc, field) => {
      acc[field.name] =
        field.validation || Yup.string().required(`${field.label} is required`);
      return acc;
    }, {}),
  );

  const showInfoModal = (e, info) => {
    e.stopPropagation();
    setInfoContent(info);
    setShowInfo(true);
  };

  const prepareValues = values => {
    if (!values?.api_password) {
      values.api_password = generateRandomString(20);
    }
    return values;
  };

  const onSubmit = async values => {
    setError(undefined);
    setManufacturerDetails(values);
    try {
      await createInverterAuthentication({
        ...prepareValues(values),
        api_name: inverterManufacturer.toUpperCase(),
      });
      history.push('/onboarding/connecting');
    } catch (error) {
      setError(error);
      console.log(error);
    }
  };

  useEffect(() => {
    setProgressPercentage(90);
  }, []);

  return (
    <>
      <h1 className="mb-3">Connect to your {inverterManufacturer} system</h1>
      <div>
        <p>
          Finally, please provide the details that Loop Optimise needs to
          connect to your system.
        </p>
        <StyledButton
          onClick={e =>
            showInfoModal(
              e,
              <>
                These details enable Loop Optimise to talk to your battery and
                ensure it operates optimally based on our forecasts for your
                home's generation and demand.
              </>,
            )
          }>
          Why does Loop Optimise need to know this?
        </StyledButton>
        <StyledFormContainer logo={logo}>
          {logo && <StyledLogo src={logo} alt="logo" />}
          <Formik
            validationSchema={validationSchema}
            onSubmit={onSubmit}
            initialValues={fields.reduce((acc, field) => {
              acc[field.name] = '';
              return acc;
            }, {})}
            validateOnChange>
            {({ handleSubmit, isValid, isSubmitting }) => (
              <Form onSubmit={handleSubmit}>
                {fields.map(field => (
                  <Field key={field.name} name={field.name}>
                    {({ field: formikField, meta }) => (
                      <Form.Group controlId={field.name}>
                        <Form.Label>{field.label}</Form.Label>
                        <Form.Control
                          type={field.type || 'text'}
                          {...formikField}
                          isValid={!meta.error && meta.touched}
                          placeholder={field.placeholder || field.label}
                          className={cx({ error: !!meta.error })}
                        />
                        {meta.error && meta.touched && (
                          <ErrorLabel label={meta.error} />
                        )}
                      </Form.Group>
                    )}
                  </Field>
                ))}

                {exampleModalContent && (
                  <p className={cx({ 'text-center': logo })}>
                    If you need help finding these details, please take a look
                    at{' '}
                    <StyledButton
                      type="button"
                      onClick={e => showInfoModal(e, exampleModalContent)}>
                      this example
                    </StyledButton>
                  </p>
                )}

                {error && (
                  <ErrorLabel
                    label={`Something went wrong - please check your details and try again. (${error})`}
                  />
                )}

                <Form.Group>
                  {!isSubmitting ? (
                    <Button
                      variant="primary"
                      disabled={!isValid || isSubmitting}
                      block
                      type="submit">
                      Continue
                    </Button>
                  ) : (
                    <LoopSpinner />
                  )}
                </Form.Group>
              </Form>
            )}
          </Formik>
        </StyledFormContainer>
      </div>

      <InfoModal
        show={showInfo}
        onClose={() => setShowInfo(false)}
        infoContent={infoContent}
      />
    </>
  );
};

export default BaseConnectionContainer;
