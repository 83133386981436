import React, { useState } from 'react';
import {
  VictoryArea,
  VictoryAxis,
  VictoryChart,
  VictoryLegend,
  VictoryLine,
} from 'victory';
import LoadingCard from '../../LoadingCard';
import StyledTitle from '../../Styled/StyledTitle';

const SystemEnergyHistoryChart = ({ data, fetchingAnalyticsData, yDomain }) => {
  const dataKeys = ['exported', 'imported', 'consumption', 'solarGeneration'];
  const colors = ['#2648b2', '#ff0085', '#3cba92', '#f7d22d'];

  const [highlightedKey, setHighlightedKey] = useState(null);

  const transformDataName = name =>
    name.charAt(0).toUpperCase() + name.slice(1);

  const handleLegendClick = clickedDataKey => {
    setHighlightedKey(prevKey =>
      prevKey === clickedDataKey ? null : clickedDataKey,
    );
  };

  const legendData = dataKeys.map((key, index) => {
    return {
      name: transformDataName(key),
      symbol: {
        fill: colors[index],
        type: highlightedKey === transformDataName(key) ? 'square' : 'none',
      },
    };
  });

  return (
    <>
      <div style={{ marginTop: 40, marginBottom: 40 }}>
        <StyledTitle>System Energy History:</StyledTitle>
        <p>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse
          paotenti. Sed eget est euismod, ultrices nisi nec, aliquet ipsum.
        </p>
      </div>
      {fetchingAnalyticsData && <LoadingCard />}
      {!fetchingAnalyticsData && data?.length > 0 && (
        <div>
          <VictoryChart
            domainPadding={10}
            width={648}
            height={400}
            domain={{ y: yDomain }}>
            <VictoryLegend
              orientation="horizontal"
              gutter={20}
              style={{
                title: { fontSize: 14 },
                labels: { cursor: 'pointer' },
              }}
              data={legendData}
              events={[
                {
                  target: ['labels', 'data'],
                  eventHandlers: {
                    onClick: (_, props) => {
                      handleLegendClick(transformDataName(props.datum.name));
                    },
                  },
                },
              ]}
            />
            <VictoryAxis
              tickFormat={(t, index) => {
                if (index % 6 === 0) {
                  return t;
                }
                return '';
              }}
              style={{
                axis: { stroke: 'transparent' },
                ticks: { stroke: 'transparent', size: 5 },
                tickLabels: {
                  fontSize: 14,
                  padding: 5,
                  angle: -90,
                  textAnchor: 'end',
                },
                grid: { stroke: 'transparent' },
              }}
              offsetY={50}
            />
            <VictoryAxis
              dependentAxis
              tickFormat={t => `${t} kWh`}
              style={{
                axis: { stroke: 'transparent' },
                ticks: { stroke: 'transparent' },
                tickLabels: {
                  fill: 'black',
                  fontSize: 14,
                  padding: 5,
                },
                grid: {
                  stroke: '#afb6b9',
                  opacity: 0.3,
                  strokeWidth: 0.5,
                  strokeDasharray: '0',
                },
                axisLabel: {
                  fontSize: 14,
                  padding: 30,
                  textAnchor: 'middle',
                  fill: 'black',
                  fontWeight: 'normal',
                },
              }}
            />

            <VictoryLine
              style={{
                data: { stroke: '#afb6b9', strokeWidth: 1 },
              }}
              y={() => 0}
            />

            {dataKeys.map((key, index) => (
              <VictoryArea
                key={key}
                name={key}
                data={data}
                x="time"
                y={key}
                style={{
                  data: {
                    fill: colors[index],
                    fillOpacity:
                      highlightedKey === null ||
                      highlightedKey === transformDataName(key)
                        ? 1
                        : 0.1,
                    strokeWidth: 0,
                  },
                }}
              />
            ))}
          </VictoryChart>
        </div>
      )}
    </>
  );
};

export default SystemEnergyHistoryChart;
