import LoopSpinnerIcon from '../assets/svg/loop-spinner.svg';
import styled from 'styled-components';

const StyledImg = styled.img`
  animation: spin 1s linear infinite;

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

const LoopSpinner = () => (
  <div className="text-center">
    <StyledImg src={LoopSpinnerIcon} />
  </div>
);

export default LoopSpinner;
