import { Button } from 'react-bootstrap';
import InformationPanel from './InformationPanel';
import styled from 'styled-components';
import { black } from '../../../theme/colors';
import { useHistory } from 'react-router-dom';

const StyledButton = styled(Button)`
  color: ${black};
  text-decoration: none;
`;

const SignOutSettings = () => {
  const history = useHistory();
  return (
    <InformationPanel title="Sign out">
      <li>
        <StyledButton
          onClick={() => history.push('/sign-out')}
          className="p-0"
          variant="link">
          Sign out of Loop Optimise
        </StyledButton>
      </li>
    </InformationPanel>
  );
};

export default SignOutSettings;
