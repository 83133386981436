import cx from 'classnames';
import { Field, FieldArray, Formik } from 'formik';
import React, { useContext, useEffect, useState } from 'react';
import { Accordion, Button, Card, Form } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import * as Yup from 'yup';
import helpSvg from '../../assets/svg/help.svg';
import { OnboardingContext } from '../../context/OnboardingContext';
import ErrorLabel from '../../ui/ErrorLabel';
import LoopSpinner from '../../ui/LoopSpinner';
import InfoModal from '../../ui/Modal/InfoModal';
import modalAngle from '../../assets/svg/solar-modal-angle.svg';
import modalDirection from '../../assets/svg/solar-modal-direction.svg';
import modalGroup from '../../assets/svg/solar-modal-group.svg';
import modalNumber from '../../assets/svg/solar-modal-number.svg';

const StyledFormContainer = styled.div`
  margin-top: 2rem;
  text-align: left;
`;

const StyledAccordion = styled(Accordion)`
  margin-bottom: 1rem;
`;

const StyledAccordionToggle = styled(Accordion.Toggle)`
  background-color: ${props =>
    props.isEmpty ? '#F5F5F5 !important' : '#FFF !important'};
  display: flex;
  flex-direction: column;
  justify-content: stretch !important;
  align-items: flex-start !important;
  padding: 20px !important;
  border: none !important;
`;

const StyledAccordionToggleHeader = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  h4 {
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 0;
  }
`;

const StyledChangeButton = styled(Button)`
  padding: 0 !important;
`;

const StyledCard = styled(Card)`
  border: ${props =>
    props.isComplete
      ? '2px solid #0ba360 !important'
      : '2px solid rgb(175, 182, 185) !important'};
  margin-bottom: 1rem;
  box-shadow: none !important;
  border-radius: 4px !important;
`;

const StyledCardBody = styled(Card.Body)`
  padding: 1rem;
  background-color: white;
  border-top: ${props =>
    props.isComplete
      ? '2px solid #0ba360 !important'
      : '2px solid rgb(175, 182, 185) !important'};
`;

const FieldLabelWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.5rem;
`;

const StyledFormLabel = styled(Form.Label)`
  margin-bottom: 0;
`;

const InfoDiv = styled.div`
  cursor: pointer;
  padding-top: 1px;
`;

const StyledButton = styled.button`
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
  outline: inherit;
  text-decoration: underline !important;
  color: #ff0085;
  display: inline-block;
`;

const StyledModalContent = styled.div`
  h3 {
    font-weight: bold;
    font-size: 22px;
    margin-bottom: 12px;
  }

  p {
    font-size: 16px;
    line-height: 1.5;
  }

  img {
    margin-top: 1rem;
    width: 100%;
  }
`;

const SolarPanelGroupsContainer = () => {
  const history = useHistory();
  const {
    setProgressPercentage,
    solarPanelArrayCount,
    solarPanelArrayData,
    setSolarPanelArrayData,
    numPanels,
    setNumPanels,
  } = useContext(OnboardingContext);
  const [activeKey, setActiveKey] = useState('0');
  const nextRoute = '/onboarding/solar-diverter';
  const [showInfo, setShowInfo] = useState(false);
  const [infoContent, setInfoContent] = useState(undefined);

  const orientationOptions = {
    0: 'North',
    45: 'Northeast',
    90: 'East',
    135: 'Southeast',
    180: 'South',
    225: 'Southwest',
    270: 'West',
    315: 'Northwest',
  };

  const tiltOptions = {
    0: '0°',
    20: '20°',
    40: '40°',
    60: '60°',
  };
  const validationSchema = Yup.object().shape({
    groups: Yup.array().of(
      Yup.object().shape({
        orientation: Yup.number().required('Please select an orientation'),
        tilt: Yup.number().required('Please select a tilt'),
        panelCount: Yup.number()
          .required('Please enter the number of panels')
          .min(1, 'Must have at least 1 panel')
          .max(100, 'Cannot exceed 100 panels'),
      }),
    ),
  });

  const allGroupPanelsFilled = groups =>
    groups.every(group => {
      return group.panelCount;
    });

  const totalPanelsFilled = groups =>
    groups.reduce((acc, group) => acc + (parseInt(group.panelCount) || 0), 0);

  const totalPanelsDifferentToNumPanels = groups =>
    allGroupPanelsFilled(groups) &&
    !!numPanels &&
    numPanels !== totalPanelsFilled(groups);

  const initialValues = {
    groups: Array.from(
      { length: parseInt(solarPanelArrayCount) },
      (_, index) => ({
        orientation:
          solarPanelArrayData?.[`group-${index + 1}`]?.orientation || '',
        tilt: solarPanelArrayData?.[`group-${index + 1}`]?.tilt || '',
        panelCount:
          solarPanelArrayData?.[`group-${index + 1}`]?.panelCount || '',
      }),
    ),
  };

  const onSubmit = values => {
    if (totalPanelsDifferentToNumPanels(values.groups)) {
      setNumPanels(totalPanelsFilled(values.groups));
    }

    const newSolarPanelArrayData = values.groups.reduce((acc, group, index) => {
      acc[`group-${index + 1}`] = group;
      return acc;
    }, {});

    setSolarPanelArrayData(newSolarPanelArrayData);
    history.push(nextRoute);
  };

  useEffect(() => {
    setProgressPercentage(45);
  }, []);

  const isGroupComplete = group => {
    return group.orientation && group.tilt && group.panelCount;
  };

  const isGroupEmpty = group => {
    return !group.orientation && !group.tilt && !group.panelCount;
  };

  const showInfoModal = (e, info) => {
    e.stopPropagation();
    setInfoContent(info);
    setShowInfo(true);
  };

  return (
    <>
      <h1 className="mb-3">Your solar panels</h1>
      <div>
        <p>
          Please tell us the <strong>direction</strong>, <strong>angle</strong>,
          and <strong>number of panels</strong> in each group of panels, on each
          part of your roof.
        </p>
        <StyledButton
          onClick={e =>
            showInfoModal(
              e,
              <StyledModalContent>
                <h3>Group of solar panels</h3>
                <p>
                  A group of solar panels is all the panels on one part of your
                  roof, all facing in the same direction. Solar panels which are
                  separated by a chimney or something else on the same part of
                  the roof, are still considered to be in the same group.
                </p>
                <p>
                  <strong>
                    If two sets of panels are facing the same way, they are the
                    same group.
                  </strong>
                </p>
                <p>
                  In the example below, there are 2 groups of solar panels. One
                  group facing west, and a second group facing south. Each have
                  8 panels in total.
                </p>
                <img src={modalGroup} alt="Group of solar panels" />
              </StyledModalContent>,
            )
          }>
          What is a group of solar panels?
        </StyledButton>
        <StyledFormContainer>
          <Formik
            validationSchema={validationSchema}
            onSubmit={onSubmit}
            initialValues={initialValues}
            validateOnChange>
            {({ handleSubmit, isValid, isSubmitting, values, errors }) => (
              <Form onSubmit={handleSubmit}>
                <FieldArray name="groups">
                  {() => (
                    <StyledAccordion
                      activeKey={activeKey}
                      onSelect={key => setActiveKey(key)}>
                      {values.groups.map((group, index) => (
                        <StyledCard
                          key={index}
                          isEmpty={isGroupEmpty(group)}
                          isComplete={isGroupComplete(group)}>
                          <StyledAccordionToggle
                            isEmpty={isGroupEmpty(group)}
                            eventKey={index.toString()}>
                            <StyledAccordionToggleHeader>
                              <h4>Solar panel group {index + 1}</h4>
                              {activeKey !== index.toString() &&
                                !isGroupEmpty(group) && (
                                  <StyledChangeButton
                                    variant="link"
                                    onClick={e => {
                                      e.stopPropagation();
                                      setActiveKey(index.toString());
                                    }}>
                                    Change
                                  </StyledChangeButton>
                                )}
                            </StyledAccordionToggleHeader>
                            {isGroupComplete(group) &&
                              activeKey !== index.toString() && (
                                <p className="mb-0">
                                  {orientationOptions[group.orientation]} |{' '}
                                  {tiltOptions[group.tilt]} | {group.panelCount}{' '}
                                  panels
                                </p>
                              )}
                          </StyledAccordionToggle>
                          <Accordion.Collapse eventKey={index.toString()}>
                            <StyledCardBody isComplete={isGroupComplete(group)}>
                              <Field name={`groups.${index}.orientation`}>
                                {({ field, meta }) => (
                                  <Form.Group
                                    controlId={`orientation-${index}`}
                                    className="text-left">
                                    <FieldLabelWrapper>
                                      <StyledFormLabel>
                                        Orientation of panels
                                      </StyledFormLabel>
                                      <InfoDiv
                                        onClick={e =>
                                          showInfoModal(
                                            e,
                                            <StyledModalContent>
                                              <h3>
                                                Direction of solar panels in a
                                                group
                                              </h3>
                                              <p>
                                                Please select the direction in
                                                which this group of solar panels
                                                faces. <br />
                                                In the example below, Group 1
                                                faces west, Group 2 faces south,
                                                and Group 3 faces east.
                                              </p>
                                              <img
                                                src={modalDirection}
                                                alt="Direction of solar panels in a group"
                                              />
                                            </StyledModalContent>,
                                          )
                                        }>
                                        <img src={helpSvg} alt="Info" />
                                      </InfoDiv>
                                    </FieldLabelWrapper>
                                    <Form.Control
                                      as="select"
                                      {...field}
                                      isValid={!meta.error && meta.touched}
                                      className={cx({ error: !!meta.error })}>
                                      <option value="">
                                        Select orientation
                                      </option>
                                      {Object.entries(orientationOptions).map(
                                        ([value, label]) => (
                                          <option key={value} value={value}>
                                            {label}
                                          </option>
                                        ),
                                      )}
                                    </Form.Control>
                                    {meta.error && meta.touched && (
                                      <ErrorLabel label={meta.error} />
                                    )}
                                  </Form.Group>
                                )}
                              </Field>

                              <Field name={`groups.${index}.tilt`}>
                                {({ field, meta }) => (
                                  <Form.Group
                                    controlId={`tilt-${index}`}
                                    className="text-left">
                                    <FieldLabelWrapper>
                                      <StyledFormLabel>
                                        Angle of panels
                                      </StyledFormLabel>
                                      <InfoDiv
                                        onClick={e =>
                                          showInfoModal(
                                            e,
                                            <StyledModalContent>
                                              <h3>
                                                Angle of solar panels in a group
                                              </h3>
                                              <p>
                                                Please select the angle that
                                                closest matches your roof
                                                section and solar panels. Select{' '}
                                                <strong>40°</strong> if you are
                                                unsure, as this is the typical
                                                for a UK roof. Examples of the
                                                roof angles are shown below:
                                              </p>
                                              <img
                                                src={modalAngle}
                                                alt="Angle of solar panels in a group"
                                              />
                                            </StyledModalContent>,
                                          )
                                        }>
                                        <img src={helpSvg} alt="Info" />
                                      </InfoDiv>
                                    </FieldLabelWrapper>
                                    <Form.Control
                                      as="select"
                                      {...field}
                                      isValid={!meta.error && meta.touched}
                                      className={cx({ error: !!meta.error })}>
                                      <option value="">Select tilt</option>
                                      {Object.entries(tiltOptions).map(
                                        ([value, label]) => (
                                          <option key={value} value={value}>
                                            {label}
                                          </option>
                                        ),
                                      )}
                                    </Form.Control>
                                    {meta.error && meta.touched && (
                                      <ErrorLabel label={meta.error} />
                                    )}
                                  </Form.Group>
                                )}
                              </Field>

                              <Field name={`groups.${index}.panelCount`}>
                                {({ field, meta }) => (
                                  <Form.Group
                                    controlId={`panelCount-${index}`}
                                    className="text-left">
                                    <FieldLabelWrapper>
                                      <StyledFormLabel>
                                        Number of panels
                                      </StyledFormLabel>
                                      <InfoDiv
                                        onClick={e =>
                                          showInfoModal(
                                            e,
                                            <StyledModalContent>
                                              <h3>
                                                Number of solar panels in a
                                                group
                                              </h3>
                                              <p>
                                                How many solar panels are in
                                                this specific group? In the
                                                example below, there are 4
                                                panels in Group 1, 11 panels in
                                                Group 2, and 4 panels in Group
                                                3.
                                              </p>
                                              <img
                                                src={modalNumber}
                                                alt="Number of solar panels in a group"
                                              />
                                            </StyledModalContent>,
                                          )
                                        }>
                                        <img src={helpSvg} alt="Info" />
                                      </InfoDiv>
                                    </FieldLabelWrapper>
                                    <Form.Control
                                      type="number"
                                      min="1"
                                      max="100"
                                      {...field}
                                      isValid={!meta.error && meta.touched}
                                      placeholder="Enter number of panels"
                                      className={cx({ error: !!meta.error })}
                                    />
                                    {meta.error && meta.touched && (
                                      <ErrorLabel label={meta.error} />
                                    )}
                                  </Form.Group>
                                )}
                              </Field>
                            </StyledCardBody>
                          </Accordion.Collapse>
                        </StyledCard>
                      ))}
                    </StyledAccordion>
                  )}
                </FieldArray>

                {totalPanelsDifferentToNumPanels(values?.groups) && (
                  <ErrorLabel
                    label={`You earlier said you had ${numPanels} panels but the total enteres here is ${totalPanelsFilled(
                      values?.groups,
                    )}. If ${totalPanelsFilled(
                      values?.groups,
                    )} is correct press Continue. Otherwise edit the numbers entered above.`}
                  />
                )}

                <Form.Group>
                  {!isSubmitting ? (
                    <Button
                      variant="primary"
                      disabled={!isValid || isSubmitting}
                      block
                      type="submit">
                      Continue
                    </Button>
                  ) : (
                    <LoopSpinner />
                  )}
                </Form.Group>
              </Form>
            )}
          </Formik>
        </StyledFormContainer>
      </div>

      <InfoModal
        show={showInfo}
        onClose={() => setShowInfo(false)}
        infoContent={infoContent}
      />
    </>
  );
};

export default SolarPanelGroupsContainer;
