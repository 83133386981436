import moment from 'moment';
import { useState, useContext, useCallback } from 'react';
import { AppContext } from '../../context/AppContext';
import { useHistory } from 'react-router-dom';
import withPageTitle from '../../hocs/withPageTitle';
import BatteryStateOfCharge from '../../ui/Dashboard/EnergyFlow/BatteryStateOfCharge';
import SystemEnergyHistoryChart from '../../ui/Dashboard/EnergyFlow/SystemEnergyHistoryChart';
import { getAnalyticsData, getUserDetails } from '../../api/Optimise';
import useOnDateChange from '../../hooks/useOnDateChange';
import {
  calculateYDomain,
  processSocChartData,
  processSystemEnergyHistoryChartData,
} from '../../utils/charts';
import WarningPanel from '../../ui/WarningPanel';
import InfoModal from '../../ui/Modal/InfoModal';

const EnergyFlowContainer = () => {
  const history = useHistory();
  const [fetchingAnalyticsData, setFetchingAnalyticsData] = useState(false);
  const [socChartData, setSocChartData] = useState([]);
  const [systemEnergyHistoryChartData, setSystemEnergyHistoryChartData] =
    useState([]);
  const [yDomain, setYDomain] = useState([0, 0]);
  const [showNoDataModal, setShowNoDataModal] = useState(false);
  const { analyticsStartDate, setShowDatePicker, userSub } =
    useContext(AppContext);

  const startDateIsToday = !!analyticsStartDate
    ? moment(analyticsStartDate).isSame(moment(), 'day')
    : true;

  const updateAnalyticsData = async selectedDate => {
    const formattedDate = moment(selectedDate || moment.unix()).format(
      'YYYY-MM-DD',
    );
    try {
      setFetchingAnalyticsData(true);
      await getAnalyticsData(formattedDate, userSub).then(analyticsData => {
        setSocChartData(processSocChartData(analyticsData));

        const processedSystemData =
          processSystemEnergyHistoryChartData(analyticsData);
        setSystemEnergyHistoryChartData(processedSystemData);
        calculateYDomain(processedSystemData, setYDomain);
      });
      setFetchingAnalyticsData(false);
    } catch (e) {
      console.log(e);
    }

    if (!startDateIsToday) {
      setShowDatePicker(true);
    } else {
      setShowDatePicker(false);
    }
  };

  const onSelectDate = useCallback(date => {
    updateAnalyticsData(date);
    history.push(`/app/energy-flow/${moment(date).unix()}`);
  }, []);

  useOnDateChange(onSelectDate);

  return !startDateIsToday ? (
    <>
      <SystemEnergyHistoryChart
        data={systemEnergyHistoryChartData}
        fetchingAnalyticsData={fetchingAnalyticsData}
        yDomain={yDomain}
      />
      <BatteryStateOfCharge
        data={socChartData}
        fetchingAnalyticsData={fetchingAnalyticsData}
      />
    </>
  ) : (
    <>
      <WarningPanel
        title="Not enough data to show your Energy Flow."
        body="Please wait 24 hours for Loop Optimise to collect your historic data."
        linkText="Why does Loop Optimise not have my data yet?"
        linkAction={() => setShowNoDataModal(true)}
      />

      <InfoModal
        show={showNoDataModal}
        onClose={() => setShowNoDataModal(false)}
        infoContent={
          <div>
            Calculating energy flow can be a complex and time-consuming process.
            For this reason, Loop Optimise performs these calculations once a
            day to provide a summary from the previous day. <br />
            <br />
            So, when you first sign up, you my need to wait up to 24 hours for
            energy flow to be available.
          </div>
        }
      />
    </>
  );
};

export default withPageTitle('Energy Flow', EnergyFlowContainer);
