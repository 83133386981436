import { Container, Row } from 'react-bootstrap';
import logoWhite from '../../assets/svg/loop-optimise-logo-white.svg';
import loginLeft from '../../assets/svg/login-left.svg';
import loginRight from '../../assets/svg/login-right.svg';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const StyledContainer = styled(Container)`
  background-color: #0c1f28;
  background-image: url(${loginLeft});
  background-position: left top;
  background-repeat: no-repeat;
  background-size: auto 100%;
  z-index: 100;

  @media (max-width: 575px) {
    background-image: none;
  }
`;

const StyledRightBgDiv = styled.div`
  background: url(${loginRight}) right top no-repeat;
  background-size: auto 100%;
  z-index: 0;
`;

const StyledLogoImg = styled.img`
  z-index: 1;
`;

const LoginBackgroundRight = (
  <StyledRightBgDiv className="position-fixed vh-100 vw-100 fixed-top d-none d-sm-block"></StyledRightBgDiv>
);

const LoginLayout = ({ children }) => (
  <StyledContainer
    fluid
    className="d-flex flex-column align-content-between justify-content-between login-background">
    {LoginBackgroundRight}
    <Row className="p-lg-5 p-md-4 p-3 justify-content-center">
      <StyledLogoImg src={logoWhite} alt="Loop Optimise" />
    </Row>
    <Row className="p-lg-5 p-md-4 p-3 justify-content-center">{children}</Row>
    <Row className="p-lg-5 p-md-4 p-3"></Row>
  </StyledContainer>
);

LoginLayout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default LoginLayout;
