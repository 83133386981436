import moment from 'moment';
import React, { useContext, useEffect } from 'react';
import styled from 'styled-components';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { useParams } from 'react-router-dom';
import { getAnalyticsData, getUserDetails } from '../../api/Optimise';
import { AppContext } from '../../context/AppContext';

const Container = styled.div`
  margin-top: 24px;
`;

const Title = styled.h3`
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 10px;
  color: #212529;
  margin-bottom: 5px;
`;

const DateSelector = styled.div`
  background-color: #eff2f6;
  padding: 12px 16px;
  border-radius: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
  cursor: pointer;

  .react-datepicker-wrapper {
    width: 100%;
  }

  input {
    background: transparent;
    border: none;
    font-size: 16px;
    width: 100%;
    cursor: pointer;
  }
`;

const DatePickerLayout = ({ children }) => {
  const { timestamp } = useParams();
  const {
    selectedDate,
    setSelectedDate,
    userDetails,
    setUserDetails,
    showDatePicker,
    userSub,
  } = useContext(AppContext);

  useEffect(() => {
    if (timestamp) {
      const date = moment.unix(timestamp).toDate();
      setSelectedDate(date);
    }

    getUserDetails(userSub).then(user => {
      setUserDetails(user);
      if (!timestamp) {
        const date = user.analytics_data_range.end_date
          ? new Date(user.analytics_data_range.end_date)
          : new Date();
        setSelectedDate(date);
      }
    });
  }, []);

  const formatDate = date => {
    const yesterday = new Date();
    yesterday.setDate(yesterday.getDate() - 1);

    if (date.toDateString() === yesterday.toDateString()) {
      return `${date.toLocaleDateString('en-GB')} (Yesterday)`;
    }
    return date.toLocaleDateString('en-GB');
  };

  return (
    <div>
      {showDatePicker && (
        <Container>
          <Title>Date</Title>
          <DateSelector>
            <DatePicker
              selected={selectedDate}
              onChange={setSelectedDate}
              dateFormat="dd/MM/yy"
              customInput={
                <input
                  value={formatDate(selectedDate)}
                  readOnly
                  className="bg-transparent border-none text-base w-full cursor-pointer"
                />
              }
              maxDate={new Date(userDetails?.analytics_data_range.end_date)}
              minDate={new Date(userDetails?.analytics_data_range.start_date)}
            />
          </DateSelector>
        </Container>
      )}

      {children}
    </div>
  );
};

export default DatePickerLayout;
