export const CHARGE_BATTERY = 'Charge Battery';
export const AVERAGE_COST = 'Average Cost';
export const USE_BATTERY = 'Use Battery';

export const getTariffKeyInfo = (
  peakTariffImportRate,
  cheapTariffImportRate,
  peakTariffExportRate,
  cheapTariffExportRate,
  tariff,
  middleTariffImportRate = null,
  middleTariffExportRate = null,
) => {
  function formatRate(rate) {
    return `${rate?.toFixed(2)} p/kWh`;
  }

  let result = {
    cheap: { color: '#D4ECE0', action: CHARGE_BATTERY },
    day: { color: '#E1D6EA', action: AVERAGE_COST },
    peak: { color: '#F8D7E1', action: USE_BATTERY },
  };

  if (!tariff.endsWith('AGILE-23-12-06')) {
    if (middleTariffImportRate === null) {
      result.cheap.import = formatRate(cheapTariffImportRate);
      result.cheap.export = formatRate(cheapTariffExportRate);
      result.day.import = formatRate(peakTariffImportRate);
      result.day.export = formatRate(peakTariffExportRate);
      result.peak = null; // No peak rate in this case
    } else {
      result.cheap.import = formatRate(cheapTariffImportRate);
      result.cheap.export = formatRate(cheapTariffExportRate);
      result.day.import = formatRate(middleTariffImportRate);
      result.day.export = formatRate(middleTariffExportRate);
      result.peak.import = formatRate(peakTariffImportRate);
      result.peak.export = formatRate(peakTariffExportRate);
    }
  } else {
    if (cheapTariffImportRate !== peakTariffImportRate) {
      result.cheap.import = `Less than ${formatRate(cheapTariffImportRate)}`;
      result.day.import = `Between ${formatRate(cheapTariffImportRate)} and ${formatRate(peakTariffImportRate)}`;
      result.peak.import = `Greater than ${formatRate(peakTariffImportRate)}`;

      if (cheapTariffExportRate !== peakTariffExportRate) {
        result.cheap.export = `Less than ${formatRate(cheapTariffExportRate)}`;
        result.day.export = `Between ${formatRate(cheapTariffExportRate)} and ${formatRate(peakTariffExportRate)}`;
        result.peak.export = `Greater than ${formatRate(peakTariffExportRate)}`;
      } else {
        result.cheap.export = formatRate(cheapTariffExportRate);
        result.day.export = formatRate(middleTariffExportRate);
        result.peak.export = formatRate(peakTariffExportRate);
      }
    } else {
      result.cheap.import = formatRate(cheapTariffImportRate);
      result.day.import = formatRate(middleTariffImportRate);
      result.peak.import = formatRate(peakTariffImportRate);

      if (cheapTariffExportRate !== peakTariffExportRate) {
        result.cheap.export = `Less than ${formatRate(cheapTariffExportRate)}`;
        result.day.export = `Between ${formatRate(cheapTariffExportRate)} and ${formatRate(peakTariffExportRate)}`;
        result.peak.export = `Greater than ${formatRate(peakTariffExportRate)}`;
      } else {
        result.cheap.export = formatRate(cheapTariffExportRate);
        result.day.export = formatRate(middleTariffExportRate);
        result.peak.export = formatRate(peakTariffExportRate);
      }
    }
  }

  return result;
};
