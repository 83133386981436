import InformationPanel from './InformationPanel';
import React, { useContext, useEffect, useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import styled from 'styled-components';
import { AppContext } from '../../../context/AppContext';
import { updateUserDetails } from '../../../api/Optimise';
import LoopSpinner from '../../LoopSpinner';
import { black, primary } from '../../../theme/colors';
import InfoModal from '../../Modal/InfoModal';
import BasicSpinner from '../../BasicSpinner';

const StyledSwitch = styled(Form.Check)`
  margin-left: 36px;
`;

const StyledRange = styled(Form.Control)``;

const StyledButton = styled(Button)``;

const StyledAnchor = styled.a`
  border: 2px solid ${black};
  color: ${black};
  text-decoration: none !important;
  font-weight: bold;
  width: 22px;
  height: 22px;
  display: block;
  text-align: center;
  border-radius: 50%;
  font-size: 14px;
  line-height: 14px;
  align-items: center;
  justify-content: center;
  display: flex;

  &:hover {
    border-color: ${primary};
  }
`;

const InverterSettings = ({ loadUser }) => {
  const { userDetails, userSub } = useContext(AppContext);
  const [switchOn, setSwitchOn] = useState(true);
  const [sliderValue, setSliderValue] = useState(10);
  const [isLoading, setIsLoading] = useState(true);
  const [isSaving, setIsSaving] = useState(false);
  const [showHelp, setShowHelp] = useState(false);
  const [showError, setShowError] = useState(false);

  useEffect(() => {
    if (!!userDetails) {
      setSwitchOn(userDetails?.simulation_mode);
      setSliderValue(userDetails?.battery_details.soc_lower_limit);
      setIsLoading(false);
    }
  }, [userDetails]);

  const handleSwitchChange = () => {
    setSwitchOn(!switchOn);
  };

  const handleSliderChange = event => {
    setSliderValue(event.target.value);
  };

  const onSubmit = () => {
    setIsSaving(true);
    updateUserDetails(
      {
        battery_soc_lower_limit: parseInt(sliderValue),
        battery_soc_upper_limit: 100,
        simulation_mode: switchOn,
      },
      userSub,
    )
      .then(() => {
        setIsSaving(false);
        loadUser();
      })
      .catch(e => {
        console.log(e);
        setIsSaving(false);
        setShowError(true);
      });
  };

  return (
    <InformationPanel title="Optimiser Settings">
      <InfoModal
        show={showHelp}
        onClose={() => setShowHelp(false)}
        infoContent={
          <div>
            Set the lowest level the Battery will discharge to. By defaul this
            is set to 10% for battery health. If you want to store more for
            emergency reasons you can, but it will reduce the impact of Loop
            Optimise.
          </div>
        }
      />
      <InfoModal
        show={showError}
        onClose={() => setShowError(false)}
        infoContent={
          <div>
            Something went wrong and we weren't able to save your changes to the
            optimiser. Please try again and if the problem persists, please{' '}
            <a target="_blank" href="https://kb.loop.homes/kb-tickets/new">
              contact support
            </a>
            .
          </div>
        }
      />
      {isLoading && <LoopSpinner />}
      {!isLoading && (
        <Form onSubmit={onSubmit}>
          <StyledSwitch
            type="switch"
            id="custom-switch"
            label="Simulation Mode"
            checked={switchOn}
            onChange={handleSwitchChange}
          />
          <p>
            If Simulation Mode is on, Loop Optimise will not make any changes to
            your battery.
          </p>
          <Form.Group controlId="socLowerLimit">
            <Form.Label className="d-flex justify-content-between">
              <div>Minimum Battery Discharge Value: {sliderValue}%</div>
              <div>
                <StyledAnchor onClick={() => setShowHelp(true)}>?</StyledAnchor>
              </div>
            </Form.Label>
            <StyledRange
              type="range"
              min={10}
              max={100}
              step={5}
              // disabled={switchOn}
              value={sliderValue}
              onChange={handleSliderChange}
            />
          </Form.Group>
          <StyledButton
            disabled={isSaving}
            className="px-3 mb-2 mt-1"
            onClick={onSubmit}>
            Save Settings
          </StyledButton>
          {isSaving && <BasicSpinner />}
        </Form>
      )}
    </InformationPanel>
  );
};

export default InverterSettings;
