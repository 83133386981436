import React, { useContext, useEffect } from 'react';
import { Alert, Button, Form } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { Formik, Field } from 'formik';
import { Auth } from 'aws-amplify';
import * as Yup from 'yup';
import cx from 'classnames';
import ErrorLabel from '../../ui/ErrorLabel';
import {
  getAddressesByPostcode,
  fakeRejectedRequest,
} from '../../api/Location';
import { usePersistedState } from '../../hooks';
import LoopSpinner from '../../ui/LoopSpinner';
import { onboardingPropTypes } from '../../propTypes';
import { getAddressById } from '../../utils/addressHelperUtil';
import { OnboardingContext } from '../../context/OnboardingContext';

const AddressContainer = () => {
  const history = useHistory();
  const {
    setProgressPercentage,
    postcode,
    setPostcode,
    setIsManualAddress,
    addressId,
    setAddressId,
    showAlert,
    setShowAlert,
    alert,
    setAlert,
  } = useContext(OnboardingContext);

  const postcodeValidationRegex =
    '^([Gg][Ii][Rr]' +
    ' 0[Aa]{2})|((([A-Za-z][0-9]{1,2})|(([A-Za-z][A-Ha-hJ-Yj-y]' +
    '[0-9]{1,2})|(([A-Za-z][0-9][A-Za-z])|([A-Za-z][A-Ha-hJ-Yj-y]' +
    '[0-9]?[A-Za-z])))) ?[0-9][A-Za-z]{2})$';

  const postcodeSchema = Yup.object().shape({
    postcode: Yup.string()
      .test('len', 'Please enter a UK postcode', val => val?.length >= 6)
      .matches(postcodeValidationRegex, 'Please enter a UK postcode')
      .required('Please enter your postcode'),
  });

  const addressSchema = Yup.object().shape({
    addressId: Yup.string().trim().required('Please select an address'),
  });

  const [isForceApiFailPostcode, setIsForceApiFailPostcode] = usePersistedState(
    'isForceApiFailPostcode',
    false,
  );
  const [addresses, setAddresses] = usePersistedState('addresses', []);
  const [showManualAddressOption, setShowManualAddressOption] =
    usePersistedState('showManualAddressOption', false);

  useEffect(() => {
    setProgressPercentage(10);
  }, []);

  const onSubmitPostcode = async ({ postcode }) => {
    setAddresses([]);
    const fakePostcode = /^hp99\s?1ab/i;
    if (fakePostcode.test(postcode)) {
      postcode = 'SW1A 1AA';
      setIsForceApiFailPostcode(true);
      setPostcode(postcode);
    } else {
      setIsForceApiFailPostcode(false);
      setPostcode(postcode.toUpperCase());
    }
    hideAlert();
    try {
      await getAddressesByPostcode(postcode.toUpperCase()).then(
        ({ addresses }) => {
          setAddresses(addresses);
        },
      );
    } catch (error) {
      const message =
        error?.response?.body?.message ||
        `Error: No addresses found for this postcode: ${postcode}. ` +
          'Note: Only UK mainland addresses are currently supported by Loop.';

      addAlert('danger', message);

      setPostcode(undefined);
    }
    setShowManualAddressOption(true);
  };

  const onSubmitAddress = async ({ addressId }) => {
    try {
      const user = await Auth.currentAuthenticatedUser();
      let address;
      if (addressId.includes('|')) {
        address = addressId;
      } else {
        const addressById = getAddressById(addressId, addresses);
        address = addressById.id;
      }
      const attributes = {
        address,
        'custom:postcode': postcode,
      };
      await Auth.updateUserAttributes(user, attributes);
      window.scrollTo(0, 0);
      if (isForceApiFailPostcode) {
        await fakeRejectedRequest();
      }
      setIsManualAddress(false);
      setAddressId(addressId);

      history.push(`/onboarding/tariff`);
    } catch (error) {
      history.push(`/onboarding/system-unavailable`);
    }
  };

  const onReset = e => {
    e.preventDefault();
    setPostcode(undefined);
    setShowManualAddressOption(false);
  };

  const onNoAddress = e => {
    history.push(`/onboarding/manual-address`);
  };

  const addAlert = (variant, message) => {
    setShowAlert(true);
    setAlert({ variant, message });
  };

  const hideAlert = () => {
    setShowAlert(false);
    setAlert(undefined);
  };

  const currentNoun = !!postcode ? 'address' : 'postcode';

  return (
    <>
      <h1 className="mb-3">What's your {currentNoun}?</h1>
      <div>
        <p>Please enter the {currentNoun} where your battery is located.</p>
      </div>
      <div>
        {!postcode && (
          <Formik
            validationSchema={postcodeSchema}
            initialValues={{ postcode }}
            onSubmit={onSubmitPostcode}
            validateOnChange>
            {({ handleSubmit }) => (
              <Form onSubmit={handleSubmit}>
                {showAlert && alert && (
                  <div>
                    <Alert
                      variant={alert.variant}
                      onClose={() => hideAlert()}
                      dismissible>
                      {alert.message}
                    </Alert>
                  </div>
                )}
                <Field name="postcode">
                  {({ field, meta }) => (
                    <Form.Group controlId="postcode" className="text-left">
                      <Form.Label>Postcode</Form.Label>
                      <Form.Control
                        type="text"
                        value={field.value}
                        onChange={field.onChange}
                        onBlur={field.onBlur}
                        isValid={!meta.error && meta.touched}
                        placeholder="Postcode"
                        className={cx({ error: !!meta.error })}
                      />
                      {meta.error && meta.touched && (
                        <ErrorLabel label={meta.error} />
                      )}
                    </Form.Group>
                  )}
                </Field>

                {showManualAddressOption && (
                  <Form.Group className="noAddress-text text-left">
                    <div>
                      Address not listed?
                      <Button
                        variant="link"
                        onClick={onNoAddress}
                        className="p-0 noAddress-text">
                        Enter your address manually
                      </Button>
                    </div>
                  </Form.Group>
                )}

                <Form.Group className="pb-0">
                  <Button
                    variant="primary"
                    className="mb-0"
                    block
                    type="submit">
                    Find my address
                  </Button>
                </Form.Group>
              </Form>
            )}
          </Formik>
        )}
        {postcode && (
          <>
            <div className="text-left">
              <Form.Group className="mb-4">
                <div>
                  <Form.Label>Postcode</Form.Label>
                </div>
                <div>
                  {postcode}{' '}
                  <Button variant="link" onClick={onReset} className="p-0">
                    Change
                  </Button>
                </div>
              </Form.Group>
              {addresses.length > 0 ? (
                <Formik
                  validationSchema={addressSchema}
                  onSubmit={onSubmitAddress}
                  initialValues={{
                    addressId,
                  }}
                  validateOnChange>
                  {({ handleSubmit, isValid, isSubmitting }) => (
                    <Form onSubmit={handleSubmit}>
                      <Field name="addressId">
                        {({ field, meta }) => (
                          <Form.Group controlId="addressId">
                            <Form.Label>Select your address</Form.Label>
                            <Form.Control
                              as="select"
                              size="md"
                              value={field.value}
                              onChange={field.onChange}
                              onBlur={field.onBlur}
                              isValid={!meta.error && meta.touched}
                              placeholder="Select your address"
                              className={cx({ error: !!meta.error })}>
                              <optgroup>
                                <option value="" key="address-select">
                                  Select your address
                                </option>
                                {addresses.map(
                                  ({ id, apiId, address }, index) => (
                                    <option
                                      key={`address-${index}`}
                                      value={apiId || id}>
                                      {address}
                                    </option>
                                  ),
                                )}
                              </optgroup>
                            </Form.Control>
                            {meta.error && meta.touched && (
                              <ErrorLabel label={meta.error} />
                            )}
                          </Form.Group>
                        )}
                      </Field>
                      <Form.Group className="noAddress-text">
                        <div>
                          Address not listed?
                          <Button
                            variant="link"
                            onClick={onNoAddress}
                            className="p-0 noAddress-text ml-1">
                            Enter your address manually
                          </Button>
                        </div>
                      </Form.Group>
                      <Form.Group>
                        {!isSubmitting ? (
                          <Button
                            variant="primary"
                            disabled={!isValid || isSubmitting}
                            block
                            className="mb-0"
                            type="submit">
                            Continue
                          </Button>
                        ) : (
                          <LoopSpinner />
                        )}
                      </Form.Group>
                    </Form>
                  )}
                </Formik>
              ) : (
                <LoopSpinner />
              )}
            </div>
          </>
        )}
      </div>
    </>
  );
};

AddressContainer.propTypes = {
  ...onboardingPropTypes,
};

export default AddressContainer;
