import {
  VictoryArea,
  VictoryAxis,
  VictoryChart,
  VictoryTooltip,
  VictoryVoronoiContainer,
} from 'victory';
import LoadingCard from '../../LoadingCard';
import StyledTitle from '../../Styled/StyledTitle';

const BatteryStateOfCharge = ({ data, fetchingAnalyticsData }) => {
  const intersectionX = data && data[data?.length - 1]?.x;

  return (
    <>
      <div style={{ marginTop: 60, marginBottom: 0 }}>
        <StyledTitle>Battery State of Charge:</StyledTitle>
        <p>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse
          potenti. Sed eget est euismod, ultrices nisi nec, aliquet ipsum.
        </p>
      </div>
      {fetchingAnalyticsData && <LoadingCard />}
      {!fetchingAnalyticsData && data?.length > 0 && (
        <div>
          <VictoryChart
            domainPadding={10}
            width={648}
            height={400}
            containerComponent={
              <VictoryVoronoiContainer
                voronoiDimension="x"
                labels={({ datum }) => `Time: ${datum?.x}\nSoC: ${datum.y}%`}
                labelComponent={
                  <VictoryTooltip
                    cornerRadius={5}
                    flyoutStyle={{
                      fill: 'white',
                      stroke: '#2648b2',
                      strokeWidth: 1,
                    }}
                  />
                }
              />
            }>
            <VictoryAxis
              dependentAxis
              tickValues={[0, 20, 40, 60, 80, 100]}
              label="Battery State of Charge (%)"
              style={{
                ticks: { stroke: 'transparent' },
                tickLabels: {
                  fill: 'black',
                  fontSize: 14,
                  padding: 5,
                },
                axis: { stroke: 'transparent' },
                axisLabel: {
                  fontSize: 14,
                  padding: 30,
                  textAnchor: 'middle',
                  fill: 'black',
                  fontWeight: 'normal',
                },
                grid: {
                  stroke: '#afb6b9',
                  opacity: 0.3,
                  strokeWidth: 0.5,
                  strokeDasharray: '0',
                },
              }}
            />
            <VictoryAxis
              tickValues={data.map(d => d?.x)}
              tickFormat={(t, index, ticks) => {
                if (
                  index === 0 ||
                  index === ticks.length - 1 ||
                  index === Math.floor(data.length / 2) ||
                  t === intersectionX
                ) {
                  return t;
                }
                return '';
              }}
              style={{
                ticks: { stroke: 'transparent', size: 5 },
                axis: { stroke: '#afb6b9', strokeWidth: 1 },
                tickLabels: {
                  fill: 'black',
                  fontSize: 14,
                  padding: 5,
                },
                axisLabel: {
                  fontSize: 14,
                  padding: 30,
                  textAnchor: 'middle',
                  fill: 'black',
                  fontWeight: 'normal',
                },
              }}
            />
            <VictoryArea
              data={data}
              style={{
                data: {
                  fill: '#2648b2',
                  fillOpacity: 0.1,
                  stroke: '#2648b2',
                  strokeWidth: 2,
                },
              }}
            />
          </VictoryChart>
        </div>
      )}
    </>
  );
};

export default BatteryStateOfCharge;
