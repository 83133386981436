import { useContext, useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { OnboardingContext } from '../../context/OnboardingContext';
import LoopSpinner from '../../ui/LoopSpinner';
import { getGeocodeForPostcode } from '../../api/Location';
import { createUserBatterySolarDetails } from '../../api/Optimise';
import yesNoToBool from '../../utils/yesNoToBool';
import awsconfig from '../../config/aws-exports';
import getBatteryMaxDischargePower from '../../utils/getBatteryMaxDischargePower';

const ConnectingContainer = () => {
  const history = useHistory();
  const {
    setProgressPercentage,
    postcode,
    battery,
    inverter,
    tariffId,
    hasEv,
    hasSolarDiverter,
    pvSystemSize,
    numPanels,
    solarPanelArrayData,
    batteryManufacturer,
    inverterManufacturer,
    hasExportLimit,
  } = useContext(OnboardingContext);
  const [requestError, setRequestError] = useState(undefined);
  useEffect(() => {
    const asyncUseEffect = async () => {
      try {
        const geocode = await getGeocodeForPostcode(postcode);
        const KW_PER_PANEL = 0.4;
        const battery_max_charge_power = Math.min(
          battery.unit_details.battery_max_charge_power,
          inverter.unit_details.battery_max_charge_power,
        );

        const battery_max_discharge_power = Math.min(
          battery.unit_details.battery_max_discharge_power,
          inverter.unit_details.battery_max_discharge_power,
        );
        const solar_capacity_kw = pvSystemSize || numPanels * KW_PER_PANEL;
        const details = {
          battery_capacity: battery.unit_details.battery_capacity,
          battery_degradation_cost:
            inverter.unit_details.battery_degradation_cost,
          battery_efficiency: inverter.unit_details.battery_efficiency,
          battery_max_charge_power,
          battery_max_discharge_power: getBatteryMaxDischargePower(
            battery_max_discharge_power,
            yesNoToBool(hasExportLimit),
          ),
          battery_soc_lower_limit: 10,
          battery_soc_upper_limit: 100,
          simulation_mode: awsconfig.signup_simulation_mode,
          tariff: tariffId,
          postcode: postcode,
          hardware_details: {
            battery: {
              manufacturer: batteryManufacturer,
              model: battery.unit_name,
            },
            inverter: {
              manufacturer: inverterManufacturer,
              model: inverter.unit_name,
            },
          },
          pv_details: {
            solar_inverter_capacity_kw: solar_capacity_kw,
            solar_module_capacity_kw: solar_capacity_kw,
            arrays: Object.values(solarPanelArrayData).map(
              ({ orientation, panelCount, tilt }) => ({
                orientation: parseInt(orientation),
                tilt: parseInt(tilt),
                panel_count: panelCount,
                latitude: geocode.latitude,
                longitude: geocode.longitude,
              }),
            ),
          },
          additional_information: {
            has_ev: yesNoToBool(hasEv),
            has_solar_diverter: yesNoToBool(hasSolarDiverter),
          },
        };
        await createUserBatterySolarDetails(details);
        setProgressPercentage(0);
        history.push('/onboarding/connected');
      } catch (error) {
        console.log(error);
        setRequestError(error);
      }
    };
    asyncUseEffect();
  }, []);

  return (
    <>
      <h1 className="mb-3">Connecting to your system</h1>
      {!requestError ? (
        <>
          <div className="mt-1 mb-4">
            <LoopSpinner />
          </div>
          <div>
            Please wait a moment while we attempt to connect to your inverter.
          </div>
          <Button
            disabled={true}
            onClick={() => history.push('/onboarding/connected')}>
            Continue
          </Button>
        </>
      ) : (
        <div>
          We encountered an error and were not able to complete your onboarding.
          Please try again and contact us if the problem persists.
        </div>
      )}
    </>
  );
};

export default ConnectingContainer;
