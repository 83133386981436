import withPageTitle from '../../hocs/withPageTitle';
import InverterSettings from '../../ui/Dashboard/Settings/InverterSettings';
import AccountInformation from '../../ui/Dashboard/Settings/AccountInformation';
import TariffInformation from '../../ui/Dashboard/Settings/TariffInformation';
import HardwareInformation from '../../ui/Dashboard/Settings/HardwareInformation';
import { useContext, useEffect } from 'react';
import { AppContext } from '../../context/AppContext';
import { getUserDetails } from '../../api/Optimise';
import SignOutSettings from '../../ui/Dashboard/Settings/SignOutSettings';
import AdditionalUserInformation from '../../ui/Dashboard/Settings/AdditionalUserInformation';

const SettingsContainer = () => {
  const { setUserDetails, userSub, isAdmin } = useContext(AppContext);
  const loadUser = () => {
    getUserDetails(userSub).then(res => {
      setUserDetails(res);
    });
  };

  useEffect(() => {
    loadUser();
  }, []);

  return (
    <div>
      {isAdmin && <AdditionalUserInformation />}
      {!isAdmin && <AccountInformation />}
      <TariffInformation />
      {/* <HardwareInformation /> */}
      <InverterSettings loadUser={loadUser} />
      <SignOutSettings />
    </div>
  );
};

export default withPageTitle('Loop Optimise Settings', SettingsContainer);
