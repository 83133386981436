import React, { useEffect, useState } from 'react';
import { Container, Col, Row, Navbar, Nav } from 'react-bootstrap';
import { withRouter } from 'react-router';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Auth } from 'aws-amplify';
import AdminBar from '../AdminBar';
import logoWhite from '../../assets/svg/loop-optimise-logo-white.svg';
import blueCurve from '../../assets/svg/blue-curve.svg';
import blueCurveSm from '../../assets/svg/blue-curve-sm.svg';
import purpleCurve from '../../assets/svg/purple-curve.svg';
import purpleCurveSm from '../../assets/svg/purple-curve-sm.svg';
import greenCurve from '../../assets/svg/green-curve.svg';
import greenCurveSm from '../../assets/svg/green-curve-sm.svg';
import yellowCurve from '../../assets/svg/yellow-curve.svg';
import yellowCurveSm from '../../assets/svg/yellow-curve-sm.svg';
import { signOut } from '../../utils/auth';

const StyledContainer = styled(Container)`
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  background-color: #f5f5f5;
  z-index: 100;
`;

const StyledNavbar = styled(Navbar)`
  padding-top: 2px;
`;

const ContentDiv = styled.div`
  z-index: 0;
`;

const CopyrightDiv = styled.div`
  font-weight: bold;
  color: #fff;
  font-size: 14px;
`;

const BackgroundShapeBlue = styled(StyledContainer)`
  background-image: url(${blueCurve});

  @media (max-width: 1024px) {
    background-image: url(${blueCurveSm});
  }
`;

const BackgroundShapePurple = styled(StyledContainer)`
  background-image: url(${purpleCurve});

  @media (max-width: 1024px) {
    background-image: url(${purpleCurveSm});
  }
`;

const BackgroundShapeGreen = styled(StyledContainer)`
  background-image: url(${greenCurve});

  @media (max-width: 1024px) {
    background-image: url(${greenCurveSm});
  }
`;

const BackgroundShapeYellow = styled(StyledContainer)`
  background-image: url(${yellowCurve});

  @media (max-width: 1024px) {
    background-image: url(${yellowCurveSm});
  }
`;

const getBackgroundShape = backgroundTint => {
  switch (backgroundTint) {
    case 'blue':
      return BackgroundShapeBlue;
    case 'green':
      return BackgroundShapeGreen;
    case 'yellow':
      return BackgroundShapeYellow;
    default:
      return BackgroundShapePurple;
  }
};

const AppLayout = ({ children, backgroundTint, modalSize = 'md' }) => {
  const history = useHistory();
  const BackgroundShapeDiv = getBackgroundShape(backgroundTint);
  const [user, setUser] = useState(undefined);
  const [isAdmin, setIsAdmin] = useState(false);

  useEffect(() => {
    const asyncUseEffectMethod = async () => {
      try {
        await Auth.currentAuthenticatedUser().then(user => {
          setUser(user);
          setIsAdmin('custom:admin_secret' in user.attributes);
        });
      } catch {
        setUser(undefined);
      }
    };
    asyncUseEffectMethod().then(r => {});
  }, []);

  return (
    <BackgroundShapeDiv
      fluid
      className="d-flex flex-column align-content-between justify-content-between login-background p-0 onboarding">
      <div>
        {isAdmin && <AdminBar user={user} onSignOut={signOut} />}
        <Container fluid>
          <Row>
            <Col className="p-0 onboarding-nav-container">
              <StyledNavbar collapseOnSelect expand="lg" variant="dark">
                <Navbar.Brand>
                  <Nav.Link href="/">
                    <img src={logoWhite} alt="Loop Energy" />
                  </Nav.Link>
                </Navbar.Brand>
                <Nav className="ml-auto">
                  {!!user && !isAdmin && (
                    <Nav.Link href="#" onClick={() => signOut(history)}>
                      Sign Out
                    </Nav.Link>
                  )}
                </Nav>
              </StyledNavbar>
            </Col>
          </Row>
        </Container>
      </div>
      <div className="flex-fill">
        <Container fluid="lg">
          <Row>
            <Col>
              <ContentDiv className="d-flex justify-content-center p-sm-3 p-xs-1 pt-3">
                {children}
              </ContentDiv>
            </Col>
          </Row>
        </Container>
      </div>
      <Navbar bg="dark" variant="dark" className={'pr-0 mt-5'}>
        <Container fluid="lg">
          <Row className="w-100 py-lg-4 py-md-3">
            <Col>
              <CopyrightDiv className="pl-0 p-2 text-center">
                &copy; {new Date().getFullYear()} Loop. All Rights Reserved.
              </CopyrightDiv>
            </Col>
          </Row>
        </Container>
      </Navbar>
    </BackgroundShapeDiv>
  );
};

AppLayout.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  backgroundTint: PropTypes.oneOf(['blue', 'green', 'purple', 'yellow']),
};

export default withRouter(AppLayout);
