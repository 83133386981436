import styled from 'styled-components';
import { StyledH3 } from '../../Styled';
import BasicSpinner from '../../BasicSpinner';
import { extraLight } from '../../../theme/colors';

const Container = styled.div`
  margin-top: 24px;
`;

const InfoContainer = styled.div`
  border: 1px solid #afb6b9;
  padding: 10px 14px;
  border-radius: 8px;

  &.secondary {
    background: ${extraLight};
    border-color: ${extraLight};
    border-radius: 4px;
  }
`;

const UnorderedList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;

  li {
    margin-bottom: 8px;

    &:last-child {
      margin-bottom: 0;
    }
  }
`;

const InformationPanel = ({
  title,
  children,
  isLoading = false,
  variant = 'primary',
}) => (
  <Container>
    <StyledH3>
      {title} {isLoading && <BasicSpinner />}
    </StyledH3>
    <InfoContainer className={variant}>
      <UnorderedList>{children}</UnorderedList>
    </InfoContainer>
  </Container>
);

export default InformationPanel;
