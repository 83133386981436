import { useContext, useEffect, useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { Formik, Field } from 'formik';
import * as Yup from 'yup';
import cx from 'classnames';
import ErrorLabel from '../../ui/ErrorLabel';
import LoopSpinner from '../../ui/LoopSpinner';
import { OnboardingContext } from '../../context/OnboardingContext';
import {
  getSupplierNames,
  getTariffsForSupplier,
} from '../../utils/tariffUtils';
import tariffs from '../../data/tariffs.json';

const TariffContainer = () => {
  const history = useHistory();
  const {
    supplier,
    setSupplier,
    tariffId,
    setTariffId,
    setProgressPercentage,
  } = useContext(OnboardingContext);

  const [suppliers, setSuppliers] = useState([]);
  const [supplierTariffs, setSupplierTariffs] = useState([]);

  const validationSchema = Yup.object().shape({
    supplier: Yup.string()
      .trim()
      .required('Please select your energy supplier'),
    tariffId: Yup.string().trim().required('Please select your tariff'),
  });

  const selectSupplier = supplier => {
    setSupplier(supplier);
    setSupplierTariffs(getTariffsForSupplier(supplier, tariffs));
  };

  useEffect(() => {
    setProgressPercentage(20);
    if (!!supplier) {
      selectSupplier(supplier);
    }
    const supplierNames = getSupplierNames(tariffs);
    setSuppliers(supplierNames);
  }, []);

  const onSelectTariff = ({ tariffId }) => {
    setTariffId(tariffId);
    history.push('/onboarding/pv-system');
  };

  return (
    <>
      <h1 className="mb-3">
        Your tariff
        <br />
        details
      </h1>
      <div>Please enter your tariff details.</div>
      {suppliers.length > 0 ? (
        <div className="text-left mt-4">
          <Formik
            validationSchema={validationSchema}
            onSubmit={onSelectTariff}
            initialValues={{
              supplier,
              tariffId,
            }}
            validateOnChange>
            {({ handleSubmit, isValid, isSubmitting }) => (
              <Form onSubmit={handleSubmit}>
                <Field name="supplier">
                  {({ field, meta }) => (
                    <Form.Group controlId="supplier">
                      <Form.Label>Who’s your energy provider?</Form.Label>
                      <Form.Control
                        as="select"
                        size="md"
                        value={field.value}
                        onChange={e => {
                          selectSupplier(e.target.value);
                          field.onChange(e);
                        }}
                        onBlur={field.onBlur}
                        isValid={!meta.error && meta.touched}
                        placeholder="Who’s your energy provider?"
                        className={cx({ error: !!meta.error })}>
                        <optgroup>
                          <option value="" key="supplier-select">
                            Who’s your energy provider?
                          </option>
                          {suppliers.map((supplier, index) => (
                            <option key={`supplier-${index}`} value={supplier}>
                              {supplier}
                            </option>
                          ))}
                        </optgroup>
                      </Form.Control>
                      {meta.error && meta.touched && (
                        <ErrorLabel label={meta.error} />
                      )}
                    </Form.Group>
                  )}
                </Field>
                <Field name="tariffId">
                  {({ field, meta }) => (
                    <Form.Group controlId="tariffId">
                      <Form.Label>What’s your tariff?</Form.Label>
                      <Form.Control
                        as="select"
                        size="md"
                        value={field.value}
                        onChange={field.onChange}
                        onBlur={field.onBlur}
                        isValid={!meta.error && meta.touched}
                        placeholder="What’s your tariff?"
                        disabled={!supplierTariffs.length}
                        className={cx({ error: !!meta.error })}>
                        <optgroup>
                          <option value="" key="tariff-select">
                            What’s your tariff?
                          </option>
                          {supplierTariffs.map(({ id, tariff }, index) => (
                            <option key={`tariff-${index}`} value={id}>
                              {tariff}
                            </option>
                          ))}
                        </optgroup>
                      </Form.Control>
                      {meta.error && meta.touched && (
                        <ErrorLabel label={meta.error} />
                      )}
                    </Form.Group>
                  )}
                </Field>
                <Form.Group className="noAddress-text">
                  <div>
                    Can’t find your tariff?
                    <Button
                      variant="link"
                      href="https://share.hsforms.com/1qFeVIeHORx-7a6BChR_1qA2uro2"
                      target="_blank"
                      className="p-0 noAddress-text ml-1">
                      Let us know here
                    </Button>
                  </div>
                </Form.Group>
                <Form.Group>
                  {!isSubmitting ? (
                    <Button
                      variant="primary"
                      disabled={
                        !isValid || isSubmitting || !supplierTariffs.length
                      }
                      block
                      className="mb-0"
                      type="submit">
                      Continue
                    </Button>
                  ) : (
                    <LoopSpinner />
                  )}
                </Form.Group>
              </Form>
            )}
          </Formik>
        </div>
      ) : (
        <LoopSpinner />
      )}
    </>
  );
};

export default TariffContainer;
