import { Auth } from 'aws-amplify';
import InformationPanel from './InformationPanel';
import { getCurrentUserInfo } from '../../../utils/auth';
import { useEffect, useState } from 'react';
import getAddressFromId from '../../../utils/getAddressFromId';

const AccountInformation = () => {
  const [currentUserInfo, setCurrentUserInfo] = useState(undefined);

  useEffect(() => {
    getCurrentUserInfo().then(res => setCurrentUserInfo(res));
  }, []);

  return (
    <InformationPanel title="Account Information" isLoading={!currentUserInfo}>
      <li>Name: {currentUserInfo?.attributes?.name}</li>
      <li>
        Address:{' '}
        {currentUserInfo?.attributes?.address &&
          getAddressFromId(currentUserInfo?.attributes?.address)}
      </li>
      <li>Email address: {currentUserInfo?.attributes?.email}</li>
      <li>Password: ••••••••</li>
    </InformationPanel>
  );
};

export default AccountInformation;
