import React, { useState, useRef, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import styled from 'styled-components';

const NavbarContainer = styled.nav`
  display: flex;
  border-bottom: 1px solid #afb6b9;
  padding: 10px 0 0;
  position: relative;
`;

const NavItem = styled(Link)`
  text-decoration: none !important;
  color: ${props => (props.active ? '#FF0085' : '#AFB6B9')};
  font-weight: bold;
  font-size: 16px;
  margin-right: 30px;
  padding-bottom: 10px;
  margin-bottom: 0px;
  transition: color 0.3s ease;

  &:hover {
    color: #ff0085;
  }
`;

const FloatingUnderline = styled.div`
  z-index: 1;
  position: absolute;
  bottom: -1px;
  left: ${props => props.left}px;
  width: ${props => props.width}px;
  height: 3px;
  background-color: #ff0085;
  transition:
    left 0.3s ease,
    width 0.3s ease;
`;

const AppNavbar = () => {
  const location = useLocation();
  const [underlineStyle, setUnderlineStyle] = useState({ left: 0, width: 0 });
  const navRefs = useRef([]);

  const navItems = [
    { name: 'Today', path: '/app/today' },
    { name: 'Savings', path: '/app/savings' },
    { name: 'Energy flow', path: '/app/energy-flow' },
    { name: 'Settings', path: '/app/settings' },
    { name: 'Help', path: '/app/help' },
  ];

  useEffect(() => {
    setTimeout(() => {
      const activeIndex = navItems.findIndex(item =>
        location.pathname.includes(item.path),
      );
      if (activeIndex !== -1 && navRefs.current[activeIndex]) {
        const activeNavItem = navRefs.current[activeIndex];
        setUnderlineStyle({
          left: activeNavItem.offsetLeft,
          width: activeNavItem.offsetWidth,
        });
      }
    }, 100); // give time for the fonts to render
  }, [location.pathname]);

  return (
    <NavbarContainer>
      <FloatingUnderline
        left={underlineStyle.left}
        width={underlineStyle.width}
      />
      {navItems.map((item, index) => (
        <NavItem
          key={item.name}
          to={item.path}
          active={location.pathname.includes(item.path)}
          ref={el => (navRefs.current[index] = el)}>
          {item.name}
        </NavItem>
      ))}
    </NavbarContainer>
  );
};

export default AppNavbar;
