import styled from 'styled-components';
import radioChecked from '../../assets/svg/radio-checked.svg';
import radioEmpty from '../../assets/svg/radio-empty.svg';

const RadioWrapper = styled.div`
  width: 100%;
  text-align: left;
  border: 2px solid #afb6b9;
  border-radius: 4px;
  margin-bottom: 24px;
  padding: 20px 15px;

  @media screen and (max-width: 575px) {
    padding: 10px 8px;
    margin-bottom: 12px;
  }

  &.selected {
    border-color: #ff0085;

    .form-check {
      background: url(${radioChecked}) no-repeat 2% center;
    }
  }

  .form-check {
    padding-left: 0;
    padding-top: 4px;
    padding-bottom: 4px;
    background: url(${radioEmpty}) no-repeat 2% center;

    input {
      display: none;
    }

    label {
      padding-left: 45px;
      cursor: pointer;
    }
  }

  .form-group {
    margin-top: 10px;
    padding-left: 45px;
    padding-right: 30px;
  }
`;

export default RadioWrapper;
